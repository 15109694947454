import React from 'react'

import '../../assets/styles/components/legal-text.scss'

const TermsOfSale = _ => {
  return (
    <main className="home-main legal-warning-main">
      <section className="background-color--dark-purple legal-warning-main__hero">
        <div className="width-content legal-warning-main__hero__text-container">
          <h1 className="title--xxl">Condiciones<br />generales de venta</h1>
        </div>
      </section>
      <section className="legal-warning-main__data">
        <div className="width-content">
          <h2 className="text--green legal-warning__text-second">1 Información general</h2>
          <p className="legal-warning__paragraph">
            La titularidad de este sitio web, www.epickidslab.com, (en adelante el Sitio Web) la ostenta la sociedad EPICKIDS
            LAB, S.L., con CIF nº B90428715, y cuyos datos de contacto son:<br />
            <br />
            · Dirección: C/ Doctor relimpio, 6, CP: 41.003 – Sevilla.<br />
            · Teléfono de contacto: +34 692 599 391.<br />
            · e-mail de contacto: online@epickidslab.com<br />
            <br />
            Este documento (así como cualquier otro documento que aquí se mencione) regula las condiciones por las que se rige
            el uso de este Sitio Web y la compra o adquisición de productos y/o servicios en el mismo (en adelante,
            Condiciones).<br />
            <br />
            A efectos de estas Condiciones se entiende que la actividad que EPICKIDS LAB desarrolla a través del Sitio Web
            comprende las actividades auxiliares de apoyo a la educación y el fomento del emprendimiento infantil.<br />
            <br />
            Además de leer las presentes Condiciones, antes de acceder, navegar y/o usar esta página web, el Usuario ha de
            haber leído el Aviso Legal y las Condiciones Generales de Uso, incluyendo, la política de cookies, y la política
            de privacidad y de protección de datos de www.epickidslab.com. Al utilizar este Sitio Web o al hacer y/o solicitar
            la adquisición de un producto y/o servicio a través del mismo el Usuario consiente quedar vinculado por estas
            Condiciones y por todo lo anteriormente mencionado, por lo que, si no está de acuerdo con todo ello, no debe usar
            este Sitio Web.<br />
            <br />
            Asimismo, se informa que estas Condiciones podrían ser modificadas. El Usuario es responsable de consultarlas cada
            vez que acceda, navegue y/o use el Sitio Web ya que serán aplicables aquellas que se encuentren vigentes en el
            momento en que se solicite la adquisición de productos y/o servicios.<br />
            <br />
            Para todas las preguntas que el Usuario pueda tener en relación con las Condiciones puede ponerse en contacto con
            el titular utilizando los datos de contacto facilitados más arriba o, en su caso, utilizando el formulario de
            contacto.
          </p>

          <h2 className="text--green legal-warning__text-second">2 El usuario</h2>
          <p className="legal-warning__paragraph">
            El acceso, la navegación y uso del Sitio Web, confiere la condición de usuario (en adelante referido,
            indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo que se aceptan, desde que se
            inicia la navegación por el Sitio Web, todas las Condiciones aquí establecidas, así como sus ulteriores
            modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento
            según el caso.<br />
            El Usuario asume su responsabilidad de un uso correcto del Sitio Web. Esta responsabilidad se extenderá a:<br />
            <br />
            · Hacer uso de este Sitio Web únicamente para realizar consultas y compras o adquisiciones legalmente válidas<br/>
            · No realizar ninguna compra falsa o fraudulenta. Si razonablemente se pudiera considerar que se ha hecho una
            compra de esta índole, podría ser anulada y se informaría a las autoridades pertinentes.<br />
            · Facilitar datos de contacto veraces y lícitos, por ejemplo, dirección de correo electrónico, dirección postal
            y/u otros datos (ver Aviso Legal y Condiciones Generales de Uso).<br />
            <br />
            El Usuario declara ser mayor de 18 años y tener capacidad legal para celebrar contratos a través de este Sitio
            Web.
          </p>

          <h2 className="text--green legal-warning__text-second">3 Proceso de compra o adquisición</h2>
          <p className="legal-warning__paragraph">
            Los Usuarios debidamente registrados pueden comprar en el Sitio Web por los medios y formas<br />
            establecidos. Deberán seguir el procedimiento de compra y/o adquisición online de www.epickidslab.com, durante el
            cual varios productos y/o servicios pueden ser seleccionados y añadidos al carrito, cesta o espacio final de
            compra y, finalmente, hacer clic en “Finalizar compra”. Asimismo, el Usuario deberá rellenar y/o comprobar la
            información que en cada paso se le solicita, aunque, durante el proceso de compra, antes de realizar el pago, se
            pueden modificar los datos de la compra. Seguidamente, el Usuario recibirá un correo electrónico confirmando que
            EPICKIDS LAB ha recibido su pedido o solicitud de compra y/o prestación del servicio, es decir, la confirmación
            del pedido.<br />
            Una vez el procedimiento de compra ha concluido, el Usuario consiente que el Sitio Web genere una factura
            electrónica que se hará llegar al Usuario a través del correo electrónico. Asimismo, el Usuario puede, si así lo
            desea, obtener una copia de su factura en papel, solicitándolo a través de los espacios de contacto del Sitio Web
            o a través de los datos de contacto facilitados más arriba.<br />
            <br />
            El Usuario reconoce que la realización del pedido de compra o adquisición materializa la aceptación plena y
            completa de las condiciones particulares de venta aplicables a cada caso.<br />
            <br />
            Igualmente, el Usuario acepta que las comunicaciones, órdenes de compra y pagos que intervengan durante las
            transacciones efectuadas en el Sitio Web podrían ser archivadas y conservadas en los registros informatizados de
            www.epickidslab.com con el fin de constituir un medio de prueba de las transacciones, en todo caso, respetando
            las condiciones razonables de seguridad y las leyes y normativas vigentes que a este respecto sean de aplicación,
            y particularmente atendiendo a la LOPD y a los derechos que asisten a los Usuarios conforme a la política de
            privacidad de este Sitio Web (Aviso Legal y Condiciones Generales de Uso).
          </p>

          <h2 className="text--green legal-warning__text-second">4 Disponibilidad</h2>
          <p className="legal-warning__paragraph">
            Todos los servicios ofertados a través del sitio web www.epickidslab.com, están sujetos a la disponibilidad del
            servicio y/o a que ninguna circunstancia o causa de fuerza mayor (cláusula nueve de estas Condiciones) afecte a
            la prestación de los mismos.<br />
            Si se produjeran dificultades en cuanto a la prestación o disponibilidad del servicio, o el mismo deviniera
            irrealizable, www.epickidslab.com se compromete a contactar al Usuario y reembolsar cualquier cantidad que
            pudiera haber sido abonada en concepto de importe.
          </p>

          <h2 className="text--green legal-warning__text-second">5 Precio y pago</h2>
          <p className="legal-warning__paragraph">
            Los precios exhibidos en el Sitio Web son los finales, en Euros (€) e incluyen los impuestos, salvo que, por
            exigencia legal, especialmente en lo relativo al IVA, se señale y aplique cuestión distinta. En ningún caso el
            Sitio Web añadirá costes adicionales al precio de un servicio de forma automática, sino solo aquellos que el
            Usuario haya seleccionado y elegido voluntaria y libremente.<br />
            <br />
            Asimismo, los Usuarios deben ser conscientes de que los precios pueden cambiar en cualquier momento, pero los
            posibles cambios no afectarán a los pedidos o compras con respecto a los que el Usuario ya haya recibido una
            confirmación de pedido.<br />
            <br />
            Los medios de pago aceptados en la página web serán Tarjeta de crédito o débito, y PayPal, asegurando EPICKIDS
            LAB que utiliza todos los medios para garantizar la confidencialidad y la seguridad de los datos de pago
            transmitidos por el Usuario durante las transacciones a través del Sitio Web. En este sentido,
            www.epickidslab.com utiliza un sistema de pago seguro SSL (Secure Socket Layer).<br />
            <br />
            Las tarjetas de crédito estarán sujetas a comprobaciones y autorizaciones por parte de la entidad bancaria emisora
            de las mismas, si dicha entidad no autorizase el pago, www.epickidslab.com no será responsable si el servicio no
            es prestado al usuario.<br />
            <br />
            Una vez que www.epickidslab.com reciba la orden de compra por parte del Usuario a través del Sitio Web, se hará
            una autorización previa en la tarjeta que corresponda para asegurar que existen fondos suficientes para completar
            la transacción. El cargo en la tarjeta se hará en el momento en que se envíe al Usuario la confirmación de envío
            y/o confirmación del servicio que se presta en forma y, en su caso, lugar establecidos.<br />
            <br />
            Si el medio de pago es Paypal, el cargo se hará en el momento en que EPICKIDS LAB envíe una confirmación del
            pedido de compra o adquisición de productos y/o servicios al Usuario.<br />
            <br />
            En todo caso, al hacer clic en “Finalizar compra” el Usuario confirma que el método de pago utilizado es suyo o
            que, en su caso, es el legítimo poseedor de la tarjeta usada.
          </p>

          <h2 className="text--green legal-warning__text-second">6 Entrega</h2>
          <p className="legal-warning__paragraph">
            El Usuario adquiere el derecho a disfrutar de los servicios contratados cuando www.epickidslab.com recibe el pago
            completo de todas las cantidades debidas en relación a la compra o adquisición efectuada, debiéndose entender el
            servicio entregado una vez el Usuario disfruta de las clases contratadas, bien presencialmente u online.<br />
            <br />
            De conformidad con lo dispuesto en la Ley 37/1992, de 28 de diciembre, del Impuesto sobre el Valor Añadido (IVA),
            los pedidos de compra para su entrega y/o prestación se entenderán localizados en el territorio de aplicación del
            IVA español si la dirección de entrega está en territorio español salvo Canarias, Ceuta y Melilla. El tipo de IVA
            aplicable será el legalmente vigente en cada momento.
          </p>

          <h2 className="text--green legal-warning__text-second">7 Medios técnicos para corregir errores</h2>
          <p className="legal-warning__paragraph">
            Se pone en conocimiento del Usuario que en caso de que detecte que se ha producido un error al introducir datos
            necesarios para procesar su solicitud de compra en el Sitio Web, podrá modificar los mismos poniéndose en contacto
            con EPICKIDS LAB, S.L., a través de los espacios de contacto habilitados en el Sitio Web, o de la dirección de
            correo electrónica facilitada previamente.<br />
            <br />
            En cualquier caso, el Usuario debe tener en cuenta que antes de hacer clic en “Finalizar compra”, tiene acceso al
            espacio, carrito, o cesta donde se van anotando sus solicitudes de compra y puede hacer modificaciones.<br />
            <br />
            De igual forma, el Usuario deberá consultar el Aviso Legal y Condiciones Generales de Uso para recabar más
            información sobre cómo ejercer su derecho de rectificación según lo establecido en la Ley Orgánica 15/1999, de 13
            de diciembre, de Protección de Datos de Carácter Personal.
          </p>

          <h2 className="text--green legal-warning__text-second">8 Derecho de desestimiento</h2>
          <p className="legal-warning__paragraph">
            El Usuario, en su calidad de consumidor, tiene la posibilidad de dejar sin efecto el contrato celebrado,
            notificándoselo a EPICKIDS LAB, S.L., en el plazo de 14 días naturales, sin necesidad de justificar la decisión y
            sin penalización de ninguna clase.<br />
            <br />
            Para ejercer este derecho de desistimiento, el Usuario deberá notificar su decisión a través de los espacios de
            contacto habilitados en el Sitio Web o a través de la dirección de correo electrónico indicada con
            anterioridad.<br/>
            <br />
            El Usuario, independientemente del medio que elija para comunicar su decisión, debe expresar de forma clara e
            inequívoca que es su intención desistir del contrato de compra. Para cumplir el plazo de desistimiento, basta con
            que la comunicación que expresa inequívocamente la decisión de desistir sea enviada antes de que venza el plazo
            correspondiente.<br />
            <br />
            En caso de desistimiento, www.epickidslab.com reembolsará al Usuario todos los pagos recibidos sin ninguna demora
            indebida y, en todo caso, a más tardar en 14 días naturales a partir de la fecha en la que EPICKIDS LAB, S.L.,es
            informado de la decisión de desistir por el Usuario.<br />
            <br />
            A este efecto, www.epickidslab.com reembolsará al Usuario utilizando el mismo método de pago que empleó este para
            realizar la transacción inicial de compra. Este reembolso no generará ningún coste adicional al Usuario.<br />
            <br />
            El Usuario reconoce saber que existen excepciones al derecho de desistimiento, tal y como se recoge en el artículo
            103 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley
            General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias. Especialmente, en las
            prestaciones de servicios, no puede ejercerse el desistimiento una vez que el servicio haya sido completamente
            ejecutado, cuando la ejecución haya comenzado, con previo consentimiento expreso del consumidor y usuario y con
            el reconocimiento por su parte de que es consciente de que, una vez que el contrato haya sido completamente
            ejecutado por el empresario, habrá perdido su derecho de desistimiento.
          </p>

          <h2 className="text--green legal-warning__text-second">9 Exoneración de responsabilidad</h2>
          <p className="legal-warning__paragraph">
            Salvo disposición legal en sentido contrario, EPICKIDS LAB, S.L., no aceptará ninguna responsabilidad por pérdidas
            que no fueran atribuibles a incumplimiento alguno por su parte, o de toda otra pérdida indirecta que no fuera
            razonablemente previsible por ambas partes en el momento en que se formalizó el contrato de prestación de
            servicios.<br />
            <br />
            Igualmente, EPICKIDS LAB, S.L., también limita su responsabilidad en relación a cualquier tipo de incidente que e
            Usuario, o persona a favor de la cual este contratase, pueda sufrir durante la prestación del servicio en las
            instalaciones o centros de EPICKIDS LAB, S.L.<br />
            <br />
            En general, www.epickidslab.com no se responsabilizará por ningún incumplimiento o retraso en el cumplimiento de
            alguna de las obligaciones asumidas, cuando el mismo se deba a acontecimientos que están fuera de nuestro control
            razonable, es decir, que se deban a causa de fuerza mayor, y ésta podrá incluir, a modo enunciativo, pero no
            exhaustivo, huelgas, cierres patronales u otras medidas reivindicativas, revueltas, invasión, amenaza o ataque
            terrorista, guerra (declarada o no) o preparativos de guerra, incendio, explosión, tormenta, inundación,
            terremoto, hundimiento, epidemia o cualquier otro desastre natural; imposibilidad de uso de trenes, barcos,
            aviones, transportes de motor u otros medios de transporte, públicos o privados; imposibilidad de utilizar
            sistemas públicos o privados de telecomunicaciones; actos, decretos, legislación, normativa o restricciones de
            cualquier gobierno o autoridad pública, especialmente relativas a prohibiciones de movilidad o
            aglomeraciones.<br />
            <br />
            De esta forma, las obligaciones quedarán suspendidas durante el periodo en que la causa de fuerza mayor continúe,
            y www.epickidslab.com pondrá todos los medios razonables para encontrar una solución que nos permita cumplir
            nuestras obligaciones a pesar de las circunstancias.
          </p>

          <h2 className="text--green legal-warning__text-second">10 Comunicacíon por escrito y notificaciones</h2>
          <p className="legal-warning__paragraph">
            El Usuario acepta que la mayor parte de las comunicaciones con EPICKIDS LAB, S.L., serán realizadas a través de
            medios electrónicos (correo electrónico o avisos publicados en el Sitio Web).<br />
            <br />
            A efectos contractuales, el Usuario consiente en usar este medio electrónico de comunicación y reconoce que todo
            contrato, notificación, información y demás comunicaciones que www.epickidslab.com envíe de forma electrónica,
            cumple con los requisitos legales de ser por escrito. En todo caso, esta condición no afectará a los derechos
            reconocidos por ley al Usuario.<br />
            <br />
            El Usuario puede enviar notificaciones y/o comunicarse con www.epickidslab.com a través de los datos de contacto
            que en estas Condiciones se facilitan y, en su caso, a través de los espacios de contacto del Sitio Web.
          </p>

          <h2 className="text--green legal-warning__text-second">11 Renuncia</h2>
          <p className="legal-warning__paragraph">
            Ninguna renuncia de www.epickidslab.com a alguna de las presentes Condiciones o a los derechos o acciones
            derivados de un contrato surtirá efecto, a no ser que se establezca expresamente que es una renuncia y se
            formalice y se le comunique al Usuario por escrito, no exonerándose al Usuario en ningún caso del cumplimiento de
            sus obligaciones.
          </p>

          <h2 className="text--green legal-warning__text-second">12 Nulidad</h2>
          <p className="legal-warning__paragraph">
            En el caso de que alguna de las presentes Condiciones fuese declarada nula y dejada sin efecto por resolución
            firme dictada por autoridad competente, el resto de las cláusulas permanecerán en vigor, sin que queden afectadas
            por dicha declaración de nulidad.
          </p>

          <h2 className="text--green legal-warning__text-second">13 Acuerdo completo</h2>
          <p className="legal-warning__paragraph">
            Las presentes Condiciones y todo documento al que se haga referencia expresa en estas constituyen el acuerdo
            íntegro existente entre el Usuario y EPICKIDS LAB, S.L., en relación con el objeto de prestación del servicio y
            sustituyen a cualquier otro pacto, acuerdo o promesa anterior convenida verbalmente o por escrito por las mismas
            partes.<br />
            <br />
            El Usuario y www.epickidslab.com reconocen haber consentido la celebración de un contrato sin haber confiado en
            ninguna declaración o promesa hecha por la otra parte, salvo aquello que figura expresamente mencionado en las
            presentes Condiciones.
          </p>

          <h2 className="text--green legal-warning__text-second">14 Protección de datos</h2>
          <p className="legal-warning__paragraph">
            Las Partes se comprometen expresamente a guardar total confidencialidad sobre los datos personales – de
            conformidad con lo establecido en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y
            garantía de los derechos digitales – a los que tengan acceso y cuyo tratamiento se efectué en razón de este
            contrato, los cuáles únicamente serán utilizados con el fin de cumplir con el objeto del mismo y conforme a los
            principios de licitud, lealtad y confidencialidad, no pudiéndose poner a disposición de terceros estos datos.
          </p>

          <h2 className="text--green legal-warning__text-second">15 Legislación aplicable y jurisdicción</h2>
          <p className="legal-warning__paragraph">
            El acceso, navegación y/o uso de este Sitio Web y los contratos de compra de productos a través del mismo se
            regirán por la legislación española.<br />
            <br />
            Así, cualquier controversia, problema o desacuerdo que surja o este relacionado con el acceso, navegación y/o uso
            del Sitio Web, o con la interpretación y ejecución de estas Condiciones, o con los contratos de venta entre
            EPICKIDS LAB, S.L., y el Usuario, será sometida a la jurisdicción exclusiva de los Juzgados y Tribunales de
            Sevilla.
          </p>

          <h2 className="text--green legal-warning__text-second">16 Quejas y reclamaciones</h2>
          <p className="legal-warning__paragraph">
            El Usuario puede hacer llegar a www.epickidslab.com sus quejas, reclamaciones o todo otro comentario que desee
            realizar a través de los datos de contacto que se facilitan al principio de estas Condiciones.<br />
            <br />
            En este sentido, EPICKIDS LAB, S.L., dispone de hojas oficiales de reclamación a disposición de los consumidores y
            usuarios que lo deseen, y que estos pueden solicitar en cualquier momento.<br />
            <br />
            <strong className="text--light-blue">Consentimiento informado uso de la plataforma
            educativa</strong><br />
            <br />
            EPICKIDS LAB, S.L., apuesta por potenciar el uso de nuevas tecnologías en el entorno educativo. Por este motivo,
            con el objetivo de llevar el aprendizaje de nuestro alumnado más allá del aula y favorecer la colaboración entre
            el profesorado, hemos creado un aula virtual a través de la aplicación www.plataforma.epickidslab.com, un sistema
            de gestión del aprendizaje que facilita crear y gestionar todo tipo de actividades formativas, y a la cual el
            usuario será redirigido a través del acceso habilitado de nuestra página web www.epickidslab.com.<br />
            <br />
            Esta aplicación, así como otras plataformas o programas a los que será necesario que el alumnado tenga acceso para
            el seguimiento de nuestras sesiones -los cuales podrán estar integrados o no en nuestra página web-, podrá
            requerir del uso de una cuenta personal del alumno, la cual será destinada a completar sus tareas, comunicarse con
            sus profesores, asistir a las clases online y/o aprender todo tipo de destrezas digitales.<br />
            <br />
            De esta manera, el padre/madre/tutor del alumno será el único responsable del uso que este realice de estos
            medios, y deberá velar, a título meramente enunciativo y no exhaustivo, por lo siguiente:<br />
            <br />
            · El adecuado cumplimiento de las condiciones y términos generales de uso de cada uno de los programas y/o
            plataformas que deban ser usadas por los alumnos, siendo el único responsable en caso de incumplimiento de las
            mismas.<br />
            · Responsabilizarse de administrar y gestionar la cuenta del alumno en todo lo que sea necesario.<br />
            · Supervisar en todo momento que el alumno hace un uso adecuado y diligente de los medios informáticos a su
            alcance, respetando en todo momento las disposiciones legales.<br />
            · Evitar que el alumno, transmita, difunda, o ponga a disposición de terceros a través del servicio, de
            informaciones, mensajes, gráficos, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en
            general, cualquier clase de material, datos o contenidos que puedan infringir o vulnerar los derechos al honor,
            intimidad y propia imagen de los demás, como material difamatorio o cuyo contenido infrinja cualquier derecho de
            propiedad intelectual o industrial.<br />
            · Evitar que el alumno, de cualquier forma, acose, amenace o abuse de otros usuarios o de cualquier tercero ajeno
            al mismo, asumiendo el padre/madre/tutor la absoluta responsabilidad para el caso de que esto sucediese.<br />
            · Evitar que el alumno incorpore a través de estas plataformas contenido censurable o que pueda herir la
            sensibilidad de cualesquiera terceros que pueda acceder a los mismos.<br />
            · Consentir, de conformidad con lo dispuesto en la Ley Orgánica 15/1999 de 13 de diciembre de Protección de Datos
            de Carácter Personal (LOPD), que los datos del usuario queden incorporados, por EPICKIDS LAB, S.L., así como por
            los titulares de cuantos programas y/o plataformas sean utilizadas en el transcurso de nuestras sesiones
            educativas, a un fichero automatizado de datos, el cual será procesado exclusivamente para fines educativos, así
            como cualquiera otros estipulados por dichos titulares.
          </p>
        </div>
      </section>
    </main>
  )
}

export default TermsOfSale