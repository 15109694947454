import React from 'react'
import { Link } from 'react-router-dom'

import EpicIsotipoGroup from '../../assets/images/Epic-isotipogroup--03.png'

import './entrepreneurship.scss'

const Entrepreneurship = _ => {
  return (
    <main className="entrepreneurship-main">
      <section className="entrepreneurship-main__information" style={{backgroundImage: `url(${EpicIsotipoGroup})`}}>
        <div className="entrepreneurship-main__information__content">
          <h1 className="entrepreneurship-main__title">METAVERSO EPIC EMPRENDEDOR</h1>
          <h2 className="entrepreneurship-main__time">Programa Trimestral</h2>
          <h2 className="entrepreneurship-main__subtitle">Emprendimiento</h2>
          <h3 className="entrepreneurship-main__schedule">Primer Trimestre</h3>
          <h4 className="entrepreneurship-main__schedule__subtitle">Objetivo:</h4>
          <p className="entrepreneurship-main__description">
            · Desarrollo de la idea de negocio.<br />
            <br />
            · Espíritu <strong>emprendedor</strong><br />
            · <strong>Ikigai</strong> y los <strong>7 hábitos</strong> de la gente altamente efectiva<br />
            · Identificar problemas y <strong>Design Thinking</strong><br />
            · Empresas y <strong>startups</strong><br />
            · <strong>Modelos de negocio</strong><br />
            · Definir <strong>mi propia idea</strong> de negocio<br />
            · <strong>Océanos rojos y azules</strong> (competencia directa e indirecta)<br />
            · Propuesta de valor y <strong>DAFO</strong><br />
            · <strong>Misión</strong>, <strong>visión</strong> y <strong>valores</strong> e <strong>imagen</strong> corporativa<br />
            · <strong>Lean Startup</strong>, vivir en <strong>Fase Beta</strong>, ciclo de mejora continua<br />
            · <strong>Business Model Canvas</strong><br />
            · Los primeros números de mi empresa y <strong>Pitch emprendedor</strong>
          </p>
    
          <h3 className="entrepreneurship-main__schedule">Segundo Trimestre</h3>
          <h4 className="entrepreneurship-main__schedule__subtitle">Objetivo:</h4>
          <p className="entrepreneurship-main__description">
            · Creación del PMV , Marketing y Ventas.<br />
            <br />
            · PMV, pivotar, ¿qué es el <strong>marketing</strong>? y tipos de marketing.<br />
            · <strong>Plan de marketing</strong>.<br />
            · Packaging, branding y <strong>publicidad</strong>.<br />
            · Ventas, canales, <strong>cadena de suministro</strong> y proveedores.<br />
            · <strong>Test</strong>.<br />
            · Análisis de <strong>datos</strong>, <strong>redes sociales</strong> e instagram.
          </p>
    
          <h3 className="entrepreneurship-main__schedule">Tercer Trismestre</h3>
          <h4 className="entrepreneurship-main__schedule__subtitle">Objetivo:</h4>
          <p className="entrepreneurship-main__description">
            · Finanzas y Deck. Ronda de inversión.<br />
            <br />
            · Ayuda al <strong>emprendimiento</strong>.<br />
            · Cómo funciona el <strong>mercado</strong>.<br />
            · <strong>Autofinanciación</strong> y <strong>métodos de inversión</strong>.<br />
            · <strong>Excel</strong>.<br />
            · Ingresos vs beneficios, aliados, <strong>balance</strong>, crear verticales y deck.<br />
            · <strong>Preparación proyecto final</strong>.<br />
            · <strong>Proyecto final</strong>.
          </p>
        </div>
      </section>
      <section className="entrepreneurship__buttons">
        <Link className="button-signup-signin signup_button" to="/plans">Registrarse</Link>
        <a className="button-signup-signin signin_button" href="https://campus.epicgrouplab.com">Iniciar sesión</a>
      </section>
    </main>
  )
}

export default Entrepreneurship