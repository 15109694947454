import React from 'react'

import CubesMan from '../../assets/images/cubes-man.png'
import Instagram from '../../assets/images/instagram--white.svg'
import Maverick from '../../assets/images/maverick.jpg'
import Ekate from '../../assets/images/ekate.jpg'
import Satoshi from '../../assets/images/satoshi.jpg'
import Ursula from '../../assets/images/ursula.jpg'

import './mentors.scss'

const Mentors = _ => {
  return (
    <main className="home-main mentors">
      <section className="background-color--light-purple mentors__hero">
        <div className="width-content mentors__hero__content">
          <div>
            <h1 className="text--gray-blue title--xxl">
              ¡Conoce a nuestros mentores de la comunidad Epic!
            </h1>
            <p className="text--gray-blue subtitle mentors__hero__description">
              Contamos con <strong>mentores especializados</strong> en cada campo y en todas las tecnologías empleadas
              en los cursos de EpicGroup Lab.
            </p>
          </div>
          <div className="mentors__hero__image-container">
            <figure className="mentors__hero__image">
              <img src={CubesMan} alt="man-cubes" />
            </figure>
          </div>
        </div>
      </section>
      <section className="mentors__meet-them">
        <div className="width-content mentors__meet-them__content">
          <div className="mentors__meet-them__description">
            <h2 className="title--xxl text--dark-purple">Conócelos a todos</h2>
            <p className="subtitle text--dark-purple mentors__meet-them__description__text">
              Descubre a los mentores de la comunidad especializados que te formarán para <strong>construir el futuro
              que sueñas</strong>.
            </p>
          </div>

          <div className="mentors__meet-them__mentor-container maverick-mentor">
            <figure className="mentors__meet-them__mentor-image">
              <img src={Maverick} alt="Maverick" />
            </figure>
            <div className="background-color--light-purple mentors__meet-them__mentor-container__circle">
              <a
                className="mentors__meet-them__mentor-container__social-media"
                href="https://www.instagram.com/maverick__dj/"
                target="_blank">
                <img src={Instagram} alt="instagram" />
              </a>
              <h3 className="subtitle mentors__meet-them__mentor-container__name">
                Maverick DJ
              </h3>
            </div>
          </div>

          <div className="mentors__meet-them__mentor-container ekate-mentor">
            <figure className="mentors__meet-them__mentor-image">
              <img src={Ekate} alt="Ekate" />
            </figure>
            <div className="background-color--light-purple mentors__meet-them__mentor-container__circle">
              <a
                className="mentors__meet-them__mentor-container__social-media"
                href="https://www.instagram.com/ohekate/"
                target="_blank">
                <img src={Instagram} alt="instagram" />
              </a>
              <h3 className="subtitle mentors__meet-them__mentor-container__name">
                Ekate
              </h3>
            </div>
          </div>

          <div className="mentors__meet-them__mentor-container satoshi-mentor">
            <figure className="mentors__meet-them__mentor-image">
              <img src={Satoshi} alt="Satoshi" />
            </figure>
            <div className="background-color--light-purple mentors__meet-them__mentor-container__circle">
              <a
                className="mentors__meet-them__mentor-container__social-media"
                href="https://www.instagram.com/satoshi.waku/"
                target="_blank">
                <img src={Instagram} alt="instagram" />
              </a>
              <h3 className="subtitle mentors__meet-them__mentor-container__name">
                Satoshi
              </h3>
            </div>
          </div>

          <div className="mentors__meet-them__mentor-container ursula-mentor">
            <figure className="mentors__meet-them__mentor-image">
              <img src={Ursula} alt="Ursula" />
            </figure>
            <div className="background-color--light-purple mentors__meet-them__mentor-container__circle">
              <a
                className="mentors__meet-them__mentor-container__social-media"
                href="https://www.instagram.com/mssblackoutt/"
                target="_blank">
                <img src={Instagram} alt="instagram" />
              </a>
              <h3 className="subtitle mentors__meet-them__mentor-container__name">
                Úrsula
              </h3>
            </div>
          </div>

          <div className="mentors__meet-them__send-your-cv">
            <h2 className="title--xxl text--dark-purple">¿Quieres ser mentor Epic?</h2>
            <p className="subtitle text--dark-purple mentors__meet-them__send-your-cv__description">
              ¡Envíanos tu CV a nuestro correo y nos pondremos en contacto contigo!
            </p>
            <div>
              <p className="paragraph--highlight text--light-blue">hola@epicgrouplab.com</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Mentors