import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Colorful from '../../assets/images/main-background1--03.jpg'

import './imageLoader.scss'

const ImageLoader = ({history}) => {
  const navigate = useNavigate()
  const redirect = _ => {
    navigate("/home-page")
  }
  useEffect(_ => {
    setTimeout(redirect, 2000)
  }, [])

  return (
    <figure className='image-loader'>
      <img src={Colorful} alt="colorful" />
    </figure>
  )
}

export default ImageLoader