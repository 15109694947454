import React from 'react'

import MapPoint from '../../assets/images/point-map-pink.svg'

const MapPointComponent = ({ componentClass, title, link }) => {
  return (
    <div className={`map-main__point-container ${componentClass}`}>
      <figure className="map-main__point-container__point-image-container">
        <img src={MapPoint} alt="map-point" />
      </figure>
      <div className="map-main__point-container__line"></div>
      <div className="map-main__point-container__text-container">
        <h4 className="map-main__point-container__text-container__title">{title}</h4>
        <a className="map-main__point-container__link" href={`https://${link}`}>
          {link}
        </a>
      </div>
    </div>
  )
}

export default MapPointComponent