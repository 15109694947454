import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import KidVrGlasses from '../../assets/images/courses-hero-image.png'
import ScheduleBlock from '../../assets/images/our-community-schedule-block.png'
import Clouds from '../../assets/images/gray-clouds.png'
import MonthlySchedule from '../../assets/images/monthly-schedule-community.jpg'

import './courses.scss'

const Courses = _ => {
  const [ t, i18n ] = useTranslation()

  return (
    <main className="home-main courses">
      <section className="background-color--dark-purple courses__hero">
        <div className="width-content courses__hero__content">
          <div>
            <h1 className="title--xxl text--gray-blue">{t('courses.hero.title')}</h1>
            <p className="subtitle text--gray-blue courses__hero__description">
              {t('courses.hero.description.1')}
              <strong>{t('courses.hero.description.2s')}</strong>
              {t('courses.hero.description.3')}
              <strong>{t('courses.hero.description.4s')}</strong>
              {t('courses.hero.description.5')}
              <br />
              <br />
              {t('courses.hero.description.6')}
              <strong>{t('courses.hero.description.7s')}</strong>
              {t('courses.hero.description.8')}
            </p>
            <Link to="/enrollment" className="button--third--small">
              <p className="paragraph--highlight">
                {t('courses.hero.button')}
              </p>
            </Link>
          </div>
          <div>
            <figure className="courses__hero__image">
              <img src={KidVrGlasses} alt="kid-vr-reality" />
            </figure>
          </div>
        </div>
      </section>

      <section className="courses__new-programs">
        <div className="width-content courses__new-programs__content">
          <div className="courses__new-programs__content__new-program">
            <h2 className="title--xxl text--dark-purple">
              {t('courses.programs.videogames.title')}
            </h2>
            <p className="subtitle text--dark-purple courses__new-programs__content__new-program__description">
              {t('courses.programs.videogames.description.1')}
              <strong>{t('courses.programs.videogames.description.2s')}</strong>
              {t('courses.programs.videogames.description.3')}
              <br />
              {t('courses.programs.videogames.description.4')}
            </p>
            <div className="button--third--large  courses__new-programs__content__new-program__button">
              <p className="paragraph--highlight">
                {t('courses.programs.videogames.button')}
              </p>
            </div>
          </div>
          <div className="courses__new-programs__content__new-program">
            <h2 className="title--xxl text--dark-purple">
              {t('courses.programs.bilingual.title')}
            </h2>
            <p className="subtitle text--dark-purple courses__new-programs__content__new-program__description">
            {t('courses.programs.bilingual.description.1')}
              <br /><br />
              <strong>{t('courses.programs.bilingual.description.2s')}</strong>
              {t('courses.programs.bilingual.description.3')}
            </p>
            <div className="button--third--large courses__new-programs__content__new-program__button">
              <p className="paragraph--highlight">
                {t('courses.programs.bilingual.button')}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="courses__programs">
        <div className="width-content courses__programs__content">
          <div className="courses__programs__program">
            <h2 className="title--xxl text--dark-purple">
              {t('courses.programs.entrepreneurship.title')}
            </h2>
            <p className="subtitle text--dark-purple courses__programs__program__description">
              {t('courses.programs.entrepreneurship.description.1')}
              <strong>{t('courses.programs.entrepreneurship.description.2s')}</strong>
              {t('courses.programs.entrepreneurship.description.3')}
            </p>
            <div className="courses__programs__program__container">
              <h3 className="subtitle text--dark-purple courses__programs__program__subtitle">
                {t('courses.programs.entrepreneurship.Q1.title')}
              </h3>
              <p className="paragraph--standard text--light-blue">
                <strong>{t('courses.programs.entrepreneurship.Q1.description.1s')}</strong><br />
                <br />
                {t('courses.programs.entrepreneurship.Q1.description.2')}
                <br />
                <br />
                {t('courses.programs.entrepreneurship.Q1.description.3')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.4')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.5')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.6')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.7')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.8')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.9')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.10')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.11')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.12')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.13')}<br />
                {t('courses.programs.entrepreneurship.Q1.description.14')}
              </p>

              <div className="courses__programs__line"></div>

              <h3 className="subtitle text--dark-purple courses__programs__program__subtitle">
                {t('courses.programs.entrepreneurship.Q2.title')}
              </h3>
              <p className="paragraph--standard text--light-blue">
                <strong>{t('courses.programs.entrepreneurship.Q1.description.1s')}</strong>
                <br />
                <br />
                {t('courses.programs.entrepreneurship.Q2.description.2')}<br />
                <br />
                {t('courses.programs.entrepreneurship.Q2.description.3')}<br />
                {t('courses.programs.entrepreneurship.Q2.description.4')}<br />
                {t('courses.programs.entrepreneurship.Q2.description.5')}<br />
                {t('courses.programs.entrepreneurship.Q2.description.6')}<br />
                {t('courses.programs.entrepreneurship.Q2.description.7')}<br />
                {t('courses.programs.entrepreneurship.Q2.description.8')}
              </p>

              <div className="courses__programs__line"></div>

              <h3 className="subtitle text--dark-purple courses__programs__program__subtitle">
                {t('courses.programs.entrepreneurship.Q3.title')}
              </h3>
              <p className="paragraph--standard text--light-blue">
                <strong>{t('courses.programs.entrepreneurship.Q3.description.1s')}</strong>
                <br />
                <br />
                {t('courses.programs.entrepreneurship.Q3.description.2')}<br />
                <br />
                {t('courses.programs.entrepreneurship.Q3.description.3')}<br />
                {t('courses.programs.entrepreneurship.Q3.description.4')}<br />
                {t('courses.programs.entrepreneurship.Q3.description.5')}<br />
                {t('courses.programs.entrepreneurship.Q3.description.6')}<br />
                {t('courses.programs.entrepreneurship.Q3.description.7')}<br />
                {t('courses.programs.entrepreneurship.Q3.description.8')}<br />
                {t('courses.programs.entrepreneurship.Q3.description.9')}
              </p>

              <Link to="/enrollment" className="button--third--small courses__programs__program__button">
                <p className="paragraph--highlight">
                  {t('courses.programs.entrepreneurship.button')}
                </p>
              </Link>
            </div>
          </div>
          <div className="courses__programs__program">
            <h2 className="title--xxl text--dark-purple">{t('courses.programs.technology.title')}</h2>
            <p className="subtitle text--dark-purple courses__programs__program__description">
              {t('courses.programs.technology.description.1')}
              <strong>{t('courses.programs.technology.description.2s')}</strong>
              {t('courses.programs.technology.description.3')}
            </p>
            <div className="courses__programs__program__container">
              <h3 className="subtitle text--dark-purple courses__programs__program__subtitle">
                {t('courses.programs.technology.Q1.title')}
              </h3>
              <p className="paragraph--standard text--light-blue">
                <strong>{t('courses.programs.technology.Q1.description.1s')}</strong>
                <br />
                <br />
                {t('courses.programs.technology.Q1.description.2')}
                <br />
                <br />
                <strong>{t('courses.programs.technology.Q1.description.3s')}</strong>
                <br />
                <br />
                {t('courses.programs.technology.Q1.description.4')}<br />
                {t('courses.programs.technology.Q1.description.5')}<br />
                {t('courses.programs.technology.Q1.description.6')}<br />
                {t('courses.programs.technology.Q1.description.7')}<br />
                {t('courses.programs.technology.Q1.description.8')}<br />
                <br />
                <strong>{t('courses.programs.technology.Q1.description.9s')}</strong>
                <br />
                <br />
                {t('courses.programs.technology.Q1.description.10')}<br />
                {t('courses.programs.technology.Q1.description.11')}<br />
                {t('courses.programs.technology.Q1.description.12')}<br />
                {t('courses.programs.technology.Q1.description.13')}<br />
                {t('courses.programs.technology.Q1.description.14')}<br />
                {t('courses.programs.technology.Q1.description.15')}<br />
                {t('courses.programs.technology.Q1.description.16')}
              </p>

              <div className="courses__programs__line"></div>

              <h3 className="subtitle text--dark-purple courses__programs__program__subtitle">
                {t('courses.programs.technology.Q2.title')}
              </h3>
              <p className="paragraph--standard text--light-blue">
                <strong>{t('courses.programs.technology.Q2.description.1s')}</strong>
                <br />
                <br />
                {t('courses.programs.technology.Q2.description.2')}<br />
                <br />
                {t('courses.programs.technology.Q2.description.3')}<br />
                {t('courses.programs.technology.Q2.description.4')}
              </p>

              <div className="courses__programs__line"></div>

              <h3 className="subtitle text--dark-purple courses__programs__program__subtitle">
                {t('courses.programs.technology.Q3.title')}
              </h3>
              <p className="paragraph--standard text--light-blue">
                <strong>{t('courses.programs.technology.Q3.description.1s')}</strong>
                <br />
                <br />
                {t('courses.programs.technology.Q3.description.2')}<br />
                <br />
                {t('courses.programs.technology.Q3.description.3')}<br />
                {t('courses.programs.technology.Q3.description.4')}
              </p>

              <Link to="/enrollment" className="button--third--small courses__programs__program__button">
                <p className="paragraph--highlight">{t('courses.programs.technology.button')}</p>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple courses__our-community">
        <div className="width-content courses__our-community__content">
          <h2 className="title--xxl text--gray-blue">
            {t('courses.community.title')}
          </h2>
          <p className="subtitle text--gray-blue courses__our-community__description">
            {t('courses.community.description.1')}
            <strong>{t('courses.community.description.2s')}</strong>
            {t('courses.community.description.3')}
            <br />
            <br />
            {t('courses.community.description.4')}
            <strong>{t('courses.community.description.5s')}</strong>
            {t('courses.community.description.6')}
          </p>
          <div className="background-color--light-purple courses__our-community__schedule-container">
            <img className="courses__our-community__schedule-image" src={MonthlySchedule} alt="schedule" />
            <div className="courses__our-community__schedule-container__message" style={{ backgroundImage: `url(${ScheduleBlock})` }}>
              <p className="title--standard text--gray-blue courses__our-community__schedule-container__message__text">
                {t('courses.community.block.1')}
                <br />
                {t('courses.community.block.2')}
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="courses__categories">
        <div className="width-content courses__categories__content" style={{ backgroundImage: `url(${Clouds})` }}>
          <div className="courses__categories__description">
            <h2 className="title--xxl text--dark-purple">
              {t('courses.categories.title')}
            </h2>
            <p className="subtitle text--dark-purple">
              <br />{t('courses.categories.description.1')}
              <strong>{t('courses.categories.description.2s')}</strong>
              {t('courses.categories.description.3')}
              <strong>{t('courses.categories.description.4s')}</strong>
              {t('courses.categories.description.5')}
              <strong>{t('courses.categories.description.6s')}</strong>
              {t('courses.categories.description.7')}
            </p>
            <p className="subtitle text--green">
              {t('courses.categories.description.8')}
              <strong>{t('courses.categories.description.9s')}</strong>
              {t('courses.categories.description.10')}
            </p>
          </div>
          <div className="background-color--light-purple courses__categories__category circle--epickids">
            <h3 className="title--standard">
              {t('courses.categories.epicKids.title')}
            </h3>
            <p className="paragraph--standard courses__categories__category__description">
              {t('courses.categories.epicKids.description.1')}
              <strong>{t('courses.categories.epicKids.description.2s')}</strong>
              {t('courses.categories.epicKids.description.3')}
            </p>
          </div>
          <div className="background-color--light-purple courses__categories__category circle--epicgenuine">
            <h3 className="title--standard">
              {t('courses.categories.epicGenuine.title')}
            </h3>
            <p className="paragraph--standard courses__categories__category__description">
              {t('courses.categories.epicGenuine.description.1')}
              <br />
              {t('courses.categories.epicGenuine.description.2')}
              <strong>{t('courses.categories.epicGenuine.description.3s')}</strong>
              {t('courses.categories.epicGenuine.description.4')}
            </p>
          </div>
          <div className="background-color--light-purple courses__categories__category circle--nextgeneration">
            <h3 className="title--standard">
              {t('courses.categories.nextGeneration.title')}
            </h3>
            <p className="paragraph--standard courses__categories__category__description">
              <strong>
                {t('courses.categories.nextGeneration.description.1s')}
                <br />
                <br />
                {t('courses.categories.nextGeneration.description.2s')}
              </strong>
              {t('courses.categories.nextGeneration.description.3')}
              <strong>{t('courses.categories.nextGeneration.description.4s')}</strong>
              {t('courses.categories.nextGeneration.description.5')}
              <strong>{t('courses.categories.nextGeneration.description.6s')}</strong>
              {t('courses.categories.nextGeneration.description.7')}
            </p>
          </div>
          <div className="background-color--light-purple courses__categories__category circle--oldschool">
            <h3 className="title--standard">
              {t('courses.categories.oldSchool.title')}
            </h3>
            <p className="paragraph--standard courses__categories__category__description">
              {t('courses.categories.oldSchool.description.1')}
              <strong>{t('courses.categories.oldSchool.description.2s')}</strong>
              {t('courses.categories.oldSchool.description.3')}
              <strong>{t('courses.categories.oldSchool.description.4s')}</strong>
              {t('courses.categories.oldSchool.description.5')}
              <strong>{t('courses.categories.oldSchool.description.6s')}</strong>
              {t('courses.categories.oldSchool.description.7')}
              <strong>{t('courses.categories.oldSchool.description.8s')}</strong>
              {t('courses.categories.oldSchool.description.9')}
              <strong>{t('courses.categories.oldSchool.description.10s')}</strong>
              {t('courses.categories.oldSchool.description.11')}
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Courses