import React from 'react'

import '../../assets/styles/components/legal-text.scss'

const ConsentToUseImages = _ => {
  return (
    <main className="home-main legal-warning-main">
      <section className="background-color--dark-purple legal-warning-main__hero">
        <div className="width-content legal-warning-main__hero__text-container">
          <h1 className="title--xxl">Consentimiento de<br />uso de imagen</h1>
        </div>
      </section>
      <section className="legal-warning-main__data">
        <div className="width-content">
          <p className="legal-warning__paragraph">
            <br /><br />
            El responsable del alumno/a autoriza a EpicGroup Lab, así como a todas aquellas terceras personas
            físicas o jurídicas a las que EpicGroup Lab pueda ceder los derechos de explotación sobre las
            fotografías, imágenes y vídeos, o parte de los mismos en las que intervenga como modelo.<br />
            <br />
            Dicha autorización no tiene ámbito geográfico determinado por lo que el fotógrafo y/o videografo
            puede ceder los derechos de explotación sobre las fotografías, imágenes y videos, o partes de los
            mismos, en las que intervenga como modelo, podrán utilizar esas fotografías, imágenes y videos, o
            partes de los mismos, en todos los países del mundo sin limitación geográfica de ninguna clase.<br />
            <br />
            Esta autorización se refiere a la totalidad de usos que puedan tener las fotografías, imágenes y
            videos, de las mismas, en las que aparezco como modelo, utilizando los medios técnicos conocidos
            en la actualidad y los que pudieran desarrollarse en el futuro, y para cualquier aplicación.<br />
            <br />
            La autorización no fija ningún límite de tiempo para su concesión ni para la explotación de  las
            fotografías, imágenes y videos, o parte de los mismos, en las que aparezco como modelo, por lo que
            la autorización se considera concedida por un plazo de tiempo ilimitado, y con carácter absolutamente
            gratuito.<br />
          </p>
        </div>
      </section>
    </main>
  )
}

export default ConsentToUseImages