import React from 'react'

import '../../assets/styles/components/legal-text.scss'

const WarningAboutUseVrTechnology = _ => {
  return (
    <main className="home-main legal-warning-main">
      <section className="background-color--dark-purple legal-warning-main__hero">
        <div className="width-content legal-warning-main__hero__text-container">
          <h1 className="title--xxl">Advertencias de<br />salud y seguridad</h1>
        </div>
      </section>
      <section className="legal-warning-main__data">
        <div className="width-content">
          <p className="legal-warning__paragraph">
            <br /><br />
            Advertencias de salud y seguridad favor de consultar.<br />
            <br />
            Nuestras sucursales usan Oculus Quest 2.<br />
            <br />
            Ingresar ahora: <a className="legal-warning__paragraph"
            href="https://www.meta.com/us/es/legal/quest/health-and-safety-warnings/"
            target="_blank">
              https://www.meta.com/us/es/legal/quest/health-and-safety-warnings/
            </a><br />
            <br />
            <strong className='text--light-blue'>El uso de esta tecnología visual y aparato electrónico depende
            de usted y los menores responsables.</strong><br />
            <br />
            Favor de de revisar nuevamente en caso de tener dudas favor de preguntar al área de atención a
            cliente en línea ó sucursal presente.<br />
            <br />
            En EpicGroup Lab cuidamos la seguridad y salud de nuestros clientes.<br />
            <br />
            Al aceptar los términos y condiciones será responsable del uso y manejo.
          </p>
        </div>
      </section>
    </main>
  )
}

export default WarningAboutUseVrTechnology