import React from 'react'

import ArrowGreen from '../../assets/images/chevron-right-green.svg'
import ArrowPurple from '../../assets/images/chevron-right.svg'
import PointMapGreen from '../../assets/images/point-map-green.svg'

import './schoolsLists.scss'

const SchoolsLists = _ => {
  return (
    <div className="schools-lists__continents">
      <div className="schools-lists__america">
        <input type="checkbox" name="schools-lists-continent" id="schools-lists-continent-america" className="" />
        <label className="schools-lists__label" for="schools-lists-continent-america">
          <p className="paragraph--highlight">Escuelas en América</p>
          <img src={ArrowPurple} alt="arrow" />
        </label>
        <ul className="schools-lists__list">
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.houston.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Houston</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.chicago.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Chicago</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.peru.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Trujillo</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.forme.ecuador.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Forme Ecuador</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.santafe.mx.epicgrouplab.com/">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Santa Fe</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.merida.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Mérida</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.jalisco.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Jalisco</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.monterrey.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Monterrey</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.veracruz.cgregg.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Veracruz</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.sanluispotosi.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab San Luis Potosí</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.puertovallarta.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Puerto Vallarta</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="http://www.chiapas.uninnova.epicgrouplab.com/"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Chiapas</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="http://www.zapopan.epicgrouplab.com/"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Zapopan</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.puebla.epicgrouplab.com/"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Puebla</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.toluca.epicgrouplab.com/"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Toluca</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://guanajuato.epicgrouplab.com/"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Guanajuato</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://zamorachinchipe.epicgrouplab.com/"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Zamora</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.santiago.chile.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Santiago Chile</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.guayas.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Guayas</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.cancun.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Cancún</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.oaxaca.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Oaxaca</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.orizaba.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Orizaba</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            href="https://www.cuenca.ecuador.epicgrouplab.com"
            className="schools-lists__list__item">
              <img
              src={PointMapGreen}
              alt="green-point"
              className="schools-lists__list__item__map-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Cuenca</p>
              <img
              src={ArrowGreen}
              alt="green-arrow"
              className="schools-lists__list__item__arrow" />
            </a>
          </li>
        </ul>
      </div>

      <div className="schools-lists__europe">
        <input type="checkbox" name="schools-lists-continent" id="schools-lists-continent-europe" />
        <label className="schools-lists__label" for="schools-lists-continent-europe">
          <p className="paragraph--highlight">Escuelas en Europa</p>
          <img src={ArrowPurple} alt="arrow" />
        </label>
        <ul className="schools-lists__list europe">
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.epicgrouplab.com/enrollment">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Central</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.ims-sotogrande.epicgrouplab.com/">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Sotogrande</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.valencia.epicgrouplab.com/">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Valencia</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.oviedo.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Oviedo</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
          <li className="schools-lists__list__item-container">
            <a
            className="schools-lists__list__item"
            href="https://www.madrid.epicgrouplab.com">
              <img
              className="schools-lists__list__item__map-point"
              src={PointMapGreen}
              alt="green-point" />
              <p className="paragraph--highlight text--gray-blue">EpicGroup Lab Madrid</p>
              <img
              className="schools-lists__list__item__arrow"
              src={ArrowGreen}
              alt="green-arrow" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SchoolsLists