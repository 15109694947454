import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

// import PhoneGreen from '../../assets/images/phone-incoming--03.png'
// import PhonePurple from '../../assets/images/phone-purple--03.png'
import YoutubeWhite from '../../assets/images/youtube--white.svg'
import TiktokWhite from '../../assets/images/tiktok--white.svg'
import TwitterWhite from '../../assets/images/twitter--white.svg'
import FacebookWhite from '../../assets/images/facebook--white.svg'
import InstagramWhite from '../../assets/images/instagram--white.svg'
import LinkedinWhite from '../../assets/images/linkedin--white.svg'
import WhatsappWhite from '../../assets/images/whatsapp--white.svg'
import EmailWhite from '../../assets/images/mail--white.svg'
import RobotOpenMind from '../../assets/images/robot-open-mind.png'

import './footer.scss'

const Footer = ({footerClassName = ''}) => {
  // const { pathname } = useLocation()
  // let footerClassName = pathname != "/" ? '' : 'dark-footer'
  const [ t, i18n ] = useTranslation()

  const closeDropdown = _ => {
    if (document.getElementById('checkbox-header-dropdown').checked) {
      document.getElementById('checkbox-header-dropdown').checked = false
    }
  }

  return (
    <footer className={`background-color--light-purple footer-main-page`}>
      <div className="width-content footer_content">
        <div className="footer_contact">
          <h4 className="text--gray-blue title--xxl footer_main-text">
            {t('footer.title')}
          </h4>
          <div className="footer-main-page__social-media">
            <div className="footer_social-media-container">
              <a
              className="footer_social-media footer_social-media-youtube"
              href="https://youtube.com/@epickidslab6868"
              target="_blank">
                <img src={YoutubeWhite} alt="youtube" />
              </a>
              <a
              className="footer_social-media"
              href="https://www.tiktok.com/@epicgrouplab?_t=8arfUNJU6ha&_r=1"
              target="_blank">
                <img src={TiktokWhite} alt="tiktok" />
              </a>
              <a
              className="footer_social-media footer_social-media-facebook"
              href="https://www.facebook.com/EpicGrouplab"
              target="_blank">
                <img src={FacebookWhite} alt="facebook" />
              </a>
              <a
              className="footer_social-media footer_social-media-instagram"
              href="https://instagram.com/epicgrouplabglobal?igshid=YmMyMTA2M2Y="
              target="_blank">
                <img src={InstagramWhite} alt="instagram" />
              </a>
              <a className="footer_social-media" href="https://wa.me/+34615124664" target="_blank">
                <img src={WhatsappWhite} alt="whatsapp" />
              </a>
            </div>
            <div>
              <Link to="/enrollment" className="button--third--small" onClick={e => {
                e.preventDefault()
              }}>
                <p className="paragraph--highlight">
                  {t('footer.enroll')}
                </p>
              </Link>
            </div>
          </div>
          <div className="footer_contact_content">
            <div>
              <div className="footer_email">
                <a
                className="text--gray-blue paragraph--highlight footer_email_email-address footer-links"
                href="mailto:hola@epicgrouplab.com?subject=Mayor%20información">
                  {t('footer.schoolEmail')}
                </a>
              </div>
              <div className="footer_email footer-main-page__second-email">
                <a
                className="text--gray-blue paragraph--highlight footer_email_email-address footer-links"
                href="mailto:expansion@epicgrouplab.com?subject=Mayor%20información">
                  {t('footer.centerEmail')}
                </a>
              </div>
            </div>
            <div className="footer-main-page__know-us-options">
              <Link
              to="/courses"
              className="text--gray-blue paragraph--highlight footer-links">
                {t('footer.courses')}
              </Link>
              <Link
              to="/centers"
              className="footer-main-page__know-us-options__second-option text--gray-blue paragraph--highlight footer-links">
                {t('footer.centers')}
              </Link>
            </div>
          </div>
          <div className="footer_second-part">
            <ul className="footer_second-part_information">
              <li>
                <Link
                className="text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                to="/legal-warning">
                  {t('footer.legalWarning')}
                </Link>
              </li>
              <li>
                <Link
                className="text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                to="/privacy-policy">
                  {t('footer.privacyPolicy')}
                </Link>
              </li>
              <li>
                <Link
                className="text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                to="/cookies-policy">
                  {t('footer.cookiesPolicy')}
                </Link>
              </li>
              <li>
                <Link
                className=" text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                to="/terms-of-sale">
                  {t('footer.termsSales')}
                </Link>
              </li>
              <li>
                <Link
                className=" text--gray-blue paragraph--highlight footer_second-part_information_text footer-links"
                to="/warning-about-the-use-of-vr-technology">
                  {t('footer.warningUseVRTechnology')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <figure className="footer-main-page__robot-open-brain-image">
          <img src={RobotOpenMind} alt="robot-open-brain" />
        </figure>
      </div>
    </footer>
  )
}

export default Footer