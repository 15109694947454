import React from 'react'

import '../../assets/styles/components/legal-text.scss'

const PrivacyPolicy = _ => {
  return (
    <main className="home-main privacy-policy-main">
      <section className="privacy-policy__text-section background-color--dark-purple legal-warning-main__hero">
        <div className="width-content legal-warning-main__hero__text-container">
          <h1 className="title--xxl">Política de privacidad</h1>
          <p className="legal-warning__paragraph">
            <br />
            <strong className="privacy-policy__paragraph--second-color">¿Quién es el responsable del tratamiento de tus datos
            personales?</strong><br />
            EPICKIDS LAB S.L. es el RESPONSABLE del tratamiento de los datos personales del USUARIO y le informa que estos
            datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril (GDPR) y la
            Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD).<br />
            <br />
            <strong className="privacy-policy__paragraph--second-color">¿Para qué tratamos tus datos personales?</strong><br />
            Para mantener una relación comercial con el usuario. Las operaciones previstas para realizar el tratamiento
            son:<br />
            <br />
            <strong className="privacy-policy__paragraph--third-color">1</strong> Remisión de comunicaciones comerciales
            publicitarias por email, fax, SMS, MMS, redes sociales sociales o cualquier otro medio electrónico o físico,
            presente o futuro, que posibilite realizar comunicaciones comerciales. Estas comunicaciones serán realizadas
            por el RESPONSABLE y relacionadas sobre sus productos y servicios, o de sus colaboradores o proveedores con los
            que éste haya alcanzado algún acuerdo de promoción. En este caso, los terceros nunca tendrán acceso a los datos
            personales.<br />
            Realizar estudios de mercado y análisis estadísticos.<br />
            <br />
            <strong className="privacy-policy__paragraph--third-color">2</strong> Tramitar encargos, solicitudes, dar respuestas
            a las consultas o cualquier tipo de petición que sea realizada por el USUARIO a través de cualquiera de las
            formas de contacto que se ponen a su disposición en la página web del RESPONSABLE.<br />
            <br />
            <strong className="privacy-policy__paragraph--third-color">3</strong> Remitir el boletín informativo online, sobre
            novedades, ofertas y promociones en nuestra actividad.<br />
            <br />
            <strong className="privacy-policy__paragraph--second-color">¿Por qué motivo podemos tratar tus datos
            personales?</strong><br />
            Porque el tratamiento está legitimado por el artículo 6 del GDPR de la siguiente forma:<br />
            <br />
            <strong className="privacy-policy__paragraph--third-color">1</strong> Por el consentimiento del USUARIO: remisión de
            comunicaciones comerciales y el boletín informativo.<br />
            <br />
            <strong className="privacy-policy__paragraph--third-color">2</strong> Por interés legítimo del RESPONSABLE: realizar
            estudios de mercado, análisis estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición del
            USUARIO.<br />
            <br />
            <strong className="privacy-policy__paragraph--second-color">¿Durante cuánto tiempo guardaremos tus datos
            personales?</strong><br />
            Se conservarán durante no más tiempo del necesario para mantener el fin del tratamiento o existan prescripciones
            legales que dictaminen su custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad
            adecuadas para garantizar la anonimización de los datos o la destrucción total de los mismos.<br />
            <br />
            <strong className="privacy-policy__paragraph--second-color">¿A quién facilitamos tus datos personales?</strong><br />
            No está prevista ninguna comunicación de datos personales a terceros salvo, si fuese necesario para el desarrollo
            y ejecución de las finalidades del tratamientoen, a nuestros proveedores de servicios relacionados con
            comunicaciones, con los cuales el RESPONSABLE tiene suscritos los contratos de confidencialidad y de encargado de
            tratamiento exigidos por la normativa vigente de privacidad.<br />
            <br />
            <strong className="privacy-policy__paragraph--second-color">¿Cuáles son tus derechos?</strong><br />
            Los derechos que asisten al USUARIO son:<br />
            <br />
            <strong className="privacy-policy__paragraph--third-color">1</strong> Derecho a retirar el consentimiento en cualquier
            momento.<br />
            <strong className="privacy-policy__paragraph--third-color">2</strong> Derecho de acceso, rectificación, portabilidad y
            supresión de sus datos y a la limitación u oposición al su tratamiento.<br />
            <strong className="privacy-policy__paragraph--third-color">3</strong> Derecho a presentar una reclamación ante la
            autoridad de control (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.<br />
            <br />
            <strong className="privacy-policy__paragraph--second-color">Datos de contacto para ejercer sus derechos:</strong><br/>
            <br />
            EPICKIDS LAB S.L.<br />
            NIF: B90428715<br />
            Domicilio: CALLE DOCTOR RELIMPIO 6, 41003 – SEVILLA<br />
            Teléfono: 692 599 391<br />
            Email: online@epickidslabs.com
          </p>
        </div>
      </section>

      <section className="legal-warning-main__data">
        <div className="width-content">
          <h2 className="text--green legal-warning__text-second">
            Consentimiento para<br />el tratamiento de datos personales
          </h2>
          <p className="legal-warning__paragraph">
            <strong className="text--light-blue">EPICKIDS LAB S.L.</strong> es el Responsable del tratamiento de los datos
            personales del Usuario y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el
            Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), por lo que
            se le facilita la siguiente información del tratamiento:<br />
            <br />
            <strong className="text--light-blue">Fines y legitimación del tratamiento:</strong> mantener una relación comercial
            (por interés legítimo del responsable, art. 6.1.f GDPR) y el envío de comunicaciones de productos o servicios
            (por consentimiento del interesado, art. 6.1.a GDPR).<br />
            <br />
            <strong className="text--light-blue">Criterios de conservación de los datos:</strong> se conservarán durante no más
            tiempo del necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen su
            custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad adecuadas para garantizar
            la anonimización de los datos o la destrucción total de los mismos.<br />
            <br />
            <strong className="text--light-blue">Comunicación de los datos:</strong> no se comunicarán los datos a terceros,
            salvo obligación legal.<br />
            <br />
            Derechos que asisten al Usuario:<br />
            <strong className="text--green">1</strong> Derecho a retirar el consentimiento en cualquier momento.<br />
            <strong className="text--green">2</strong> Derecho de acceso, rectificación, portabilidad y supresión de sus
            datos y a la limitación u oposición al su tratamiento.<br />
            <strong className="text--green">3</strong> Derecho a presentar una reclamación ante la autoridad de control (www.aepd.es) si considera que el tratamiento
            no se ajusta a la normativa vigente.<br />
            <br />
            <strong className="text--light-blue">Datos de contacto para ejercer sus derechos:</strong>

            EPICKIDS LAB S.L.
            NIF: B90428715
            Domicilio: CALLE DOCTOR RELIMPIO 6, 41003 – SEVILLA
            Teléfono: 692 599 391
            Email: online@epickidslabs.com
          </p>
          <h2 className="text--green legal-warning__text-second">
            Consentimiento en la INSCRIPCIÓN A ACTIVIDADES
          </h2>
          <p className="legal-warning__paragraph">
            <strong className="text--light-blue">PROTECCIÓN DE DATOS:</strong> De acuerdo con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril
            (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), consiento que mis datos se traten bajo la
            responsabilidad de EPICKIDS LAB S.L. para enviarme información comercial y que los conserve durante no más tiempo
            del necesario para mantener el fin del tratamiento o mientras existan prescripciones legales que dictaminen su
            custodia. Autorizo a que comuniquen los datos a sus empresas asociadas para la misma finalidad. Me doy por
            informado de que tengo derecho a revocar este consentimiento en cualquier momento y a ejercer los derechos de
            acceso, rectificación, portabilidad y supresión de mis datos, y los de limitación y oposición al tratamiento
            dirigiéndome a CALLE DOCTOR RELIMPIO, 6 – 41003 SEVILLA. Email: online@epickidslab.com. También estoy informado de
            que puedo reclamar ante la autoridad de control a www.aepd.es.
          </p>
          <h2 className="text--green legal-warning__text-second">E-MAIL: correo electrónico y publicidad</h2>
          <p className="legal-warning__paragraph">
            <strong className="text--light-blue">PROTECCIÓN DE DATOS:</strong> De conformidad con lo dispuesto en el Reglamento
            (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), le informamos de que
            los datos personales y la dirección de correo electrónico del interesado, se tratarán bajo la responsabilidad de
            EPICKIDS LAB S.L. por un interés legítimo y para el envío de comunicaciones sobre nuestros productos y servicios, y
            se conservarán mientras ninguna de las partes se oponga a ello. Los datos no se comunicarán a terceros, salvo
            obligación legal. Le informamos de que puede ejercer los derechos de acceso, rectificación, portabilidad y supresión
            de sus datos y los de limitación y oposición a su tratamiento dirigiéndose a CALLE DOCTOR RELIMPIO, 6 – 41003
            SEVILLA. Email: online@epickidslab.com. Si considera que el tratamiento no se ajusta a la normativa vigente, podrá
            presentar una reclamación ante la autoridad de control en www.aepd.es.<br />
            <br />
            <strong className="text--light-blue">PUBLICIDAD:</strong> En cumplimiento de lo previsto en el artículo 21 de la
            Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE), si usted no desea recibir
            más información sobre nuestros productos y/o servicios, puede darse de baja enviando un correo electrónico a
            online@epickidslab.com, indicando en el Asunto «BAJA» o «NO ENVIAR».
          </p>
          <h2 className="text--green legal-warning__text-second">Consentimiento para<br />el tratamiento de datos personales</h2>
          <p className="legal-warning__paragraph">
            <strong className="text--light-blue">EPICKIDS LAB S.L.</strong> es el Responsable del tratamiento de los datos
            personales del Usuario y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el
            Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), por lo que
            se le facilita la siguiente información del tratamiento:<br />
            <br />
            <strong className="text--light-blue">Fines y legitimación del tratamiento:</strong> mantener una relación comercial
            (por interés legítimo del responsable, art. 6.1.f GDPR) y el envío de comunicaciones de productos o servicios
            (por consentimiento del interesado, art. 6.1.a GDPR).<br />
            <br />
            <strong className="text--light-blue">Criterios de conservación de los datos:</strong> se conservarán durante no más
            tiempo del necesario para mantener el fin del tratamiento o existan prescripciones legales que dictaminen su
            custodia y cuando ya no sea necesario para ello, se suprimirán con medidas de seguridad adecuadas para garantizar
            la anonimización de los datos o la destrucción total de los mismos.<br />
            <br />
            <strong className="text--light-blue">Comunicación de los datos:</strong> no se comunicarán los datos a terceros,
            salvo obligación legal.<br />
            <br />
            Derechos que asisten al Usuario:<br />
            <strong className="text--green">1</strong> Derecho a retirar el consentimiento en cualquier momento.<br />
            <strong className="text--green">2</strong> Derecho de acceso, rectificación, portabilidad y supresión de sus datos
            y a la limitación u oposición al su tratamiento.<br />
            <strong className="text--green">3</strong> Derecho a presentar una reclamación ante la autoridad de control
            (www.aepd.es) si considera que el tratamiento no se ajusta a la normativa vigente.<br />
            <br />
            <strong className="text--light-blue">Datos de contacto para ejercer sus derechos:</strong><br />
            <br />
            EPICKIDS LAB S.L.<br />
            NIF: B90428715<br />
            Domicilio: CALLE DOCTOR RELIMPIO 6, 41003 – SEVILLA<br />
            Teléfono: 692 599 391<br />
            Email: online@epickidslabs.com
          </p>
        </div>
      </section>
    </main>
  )
}

export default PrivacyPolicy