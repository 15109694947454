import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Map from '../../components/Map/Map'

// import PopUpModal from '../../components/Modals/Bootcamp/Bootcamp'

// New
import BlockBigMan from '../../assets/images/big-main-container.png'
import BlockPlazasUnlimited from '../../assets/images/block-plazas-unlimited.png'
import BlockOpenYourCenter from '../../assets/images/open-your-center-container.png'
import CurvedLine from '../../assets/images/curved-line.png'
import BlockOurCommunity from '../../assets/images/block-our-community.png'
import EpicGroupLabVideo from '../../assets/videos/epicgrouplab-cambio-360.mp4'
import Balloon from '../../assets/images/balloon.png'
import ShiningStar from '../../assets/images/shining-star-orange.png'
import MexicoFlag from '../../assets/images/mexico-flag.png'
import SpainFlag from '../../assets/images/spain-flag.png'
import EcuadorFlag from '../../assets/images/ecuador-flag.png'
import PDFBootcamp from '../../assets/downloadable-files/EPIC-BOOTCAMP-INTERNACIONAL-verano23.pdf'

import MetaverseMan from '../../assets/images/hombre-metaverso.png'
import BigMan from '../../assets/images/big-man.png'
import Building from '../../assets/images/illustration-building.png'
import Worlds from '../../assets/images/worlds.png'
import OfficeImage from '../../assets/images/office-image.png'
import OneGreenWorld from '../../assets/images/one-green-world.png'
import TwoWorlds from '../../assets/images/two-worlds.png'
import ComputerScreen from '../../assets/images/computer-screen.png'
import PlayStationLogo from '../../assets/images/playstation.png'
import SecondSponsorLogo from '../../assets/images/second-sponsor-image.png'
import UCadizLogo from '../../assets/images/universidad-cadiz.png'
import IkeaLogo from '../../assets/images/ikea.png'
import HuaweiLogo from '../../assets/images/huawei.png'
import AinderLogo from '../../assets/images/ainder-image.png'
import ElMundoLogo from '../../assets/images/el-mundo-press.png'
import EmprendedoresLogo from '../../assets/images/emprendedores-press.png'
import DiarioDeJerezLogo from '../../assets/images/diario-de-jerez-press.png'
import ChevronRight from '../../assets/images/chevron-right.svg'
import FaqsImage from '../../assets/images/faqs-image.png'
import MonthlySchedule from '../../assets/images/monthly-schedule-community.jpg'

import './HomePage.scss'
import '../../assets/styles/components/world.scss'

const HomePage = _ => {
  const [loading, setLoading] = useState(true)
  const [mobileSize, setMobileSize] = useState(true)
  const { t, i18n } = useTranslation();

  const MaxWidthScreen = _ => {
    const screenSize = window.matchMedia('(max-width: 1024px)')
    if(screenSize.matches) {
      setMobileSize(true)
    } else {
      setMobileSize(false)
    }
  }
  document.addEventListener('resize', MaxWidthScreen)

  useEffect(_ => {
    MaxWidthScreen()

    setTimeout( _ => {
      setLoading(false)
    }, 2000)
  }, [])


  // if (loading) {
  //   return (
  //     <ImageLoader />
  //   )
  // }
  return (
    <main className="home-main">

      <section className="background-color--dark-purple home-hero">
        <div className="home-hero__content width-content" style={{ backgroundImage: `url(${BlockBigMan})` }}>
          <div className="home-hero__text-container">
            <h1 className="home-hero__title title--xxl text--gray-blue">
              {t('homePage.hero.title.1')}
              <br />
              {t('homePage.hero.title.2')}
            </h1>
            <p className="home-hero__text subtitle text--gray-blue">
              {t('homePage.hero.description')}
            </p>
            <Link to="/enrollment" className="button--third--small">
              <p className="paragraph--highlight">{t('homePage.hero.button')}</p>
            </Link>
            {/* <a className="home-hero__know-more" href="../about-us/index.html">
              Conoce más sobre Epic
              <img className="home-hero__know-more__arrow" src="../../src/assets/images/arrow-purple-right--03.png" alt="arrow">
            </a> */}
          </div>
          <div className="home-hero__figure-container">
            <figure className="home-hero__image-container">
              <img src={MetaverseMan} alt="home-hero" />
            </figure>
          </div>
        </div>
      </section>

      <section className="home-enrollment">
        <div className="home-enrollment__container width-content">
          <div className="home-enrollment__image-information">
            <figure className="home-enrollment__image-container">
              <img src={BigMan} alt="big-man" />
            </figure>
            <p className="subtitle text--green home-enrollment__image-information__date">
              {t('homePage.enrollment.imageDate.1')}
              <br />
              {t('homePage.enrollment.imageDate.2')}
            </p>
            <div
            className="home-enrollment__image-information__block"
            style={{ backgroundImage: `url(${BlockPlazasUnlimited})` }}>
              <h3 className="text--gray-blue title--standard home-enrollment__image-information__block__text">
                {t('homePage.enrollment.imageBlock.1')}
                <br />
                {t('homePage.enrollment.imageBlock.2')}
              </h3>
            </div>
          </div>
          <div className="home-enrollment__further-information">
            <h2 className="title--xxl text--dark-purple">
              {t('homePage.enrollment.title')}
            </h2>
            <p className="subtitle text--dark-purple home-enrollment__further-information__description">
              {t('homePage.enrollment.description.1')}
              <br /><br />
              {t('homePage.enrollment.description.2')}
              <br /><br />
              <strong className="subtitle text--green">
                {t('homePage.enrollment.date')}
              </strong>
            </p>
            <a href="https://wa.me/+34692599391" target="_blank" className="button--first--large">
              <p className="paragraph--highlight">
                {t('homePage.enrollment.button.1')}&nbsp;
                <strong>{t('homePage.enrollment.button.2s')}</strong>
                {t('homePage.enrollment.button.3')}
              </p>
            </a>
            <div className="home-enrollment__further-information__options-container">
              <Link to="/courses">
                <h3 className="subtitle text--green">
                  {t('homePage.enrollment.furtherInformation.onlineCourses')}
                </h3>
              </Link>
              <Link to="/courses">
                <h3 className="subtitle text--green">
                  {t('homePage.enrollment.furtherInformation.presentialCourses')}
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple home-video-section">
        <div className="width-content home-video-section__content">
          <h2 className="title--standard text--gray-blue">
            {t('homePage.video.title')}
          </h2>
          <div className="home-video-section__further-information-container">
            <div
            className="home-video-section__further-information-container__video-box"
            style={{ backgroundImage: `url(${Building})` }}>
              <figure
                className="background-color--light-purple
                home-video-section__further-information-container__video-box__video-container">
                <video className="" src={EpicGroupLabVideo} controls></video>
              </figure>
            </div>
            <div
            className="home-video-section__further-information-container__worlds-container"
            style={{ backgroundImage: `url(${Worlds})` }}>
              <p
                className="text--gray-blue paragraph--standard
                home-video-section__further-information-container__worlds-container__text-first">
                {t('homePage.video.furtherInformation.first.1')}
                <strong>{t('homePage.video.furtherInformation.first.2s')}</strong>
              </p>
              <p
              className="text--gray-blue paragraph--standard
              home-video-section__further-information-container__worlds-container__text-second">
                {t('homePage.video.furtherInformation.second.1')}
                <strong>{t('homePage.video.furtherInformation.second.2s')}</strong>
                {t('homePage.video.furtherInformation.second.3')}
                <strong>{t('homePage.video.furtherInformation.second.4s')}</strong>
              </p>
              <p
              className="text--gray-blue paragraph--standard
              home-video-section__further-information-container__worlds-container__text-third">
                {t('homePage.video.furtherInformation.third.1')}
                <strong>{t('homePage.video.furtherInformation.third.2s')}</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="background-color--light-purple home-worlds">
        <div className={`${mobileSize ? '' : 'width-content'} home-worlds__content`}>
          <div className="home-worlds__text-container">
            <div className="home-worlds__text-container__content">
              <h2 className="text--gray-blue title--xxl home-worlds__text-container__content__title">
                {t('homePage.world.title')}
              </h2>
              <p className="text--gray-blue subtitle">
                {t('homePage.world.description.1')}
                <br />
                {t('homePage.world.description.2')}
              </p>
            </div>
            <div style={{ backgroundImage: `url(${BlockOpenYourCenter})` }} className="home-worlds__open-your-center">
              <img className="home-worlds__open-your-center__curved-line" src={CurvedLine} alt="curved-line" />
              <h3 className="text--gray-blue subtitle home-worlds__open-your-center__you-want-to-open-a-center">
                {t('homePage.world.openYourCenter')}
              </h3>
              <Link to="/centers" className="button--third--small">
                <p className="text--gray-blue paragraph--highlight">
                  {t('homePage.world.button')}
                </p>
              </Link>
            </div>
          </div>
          <div className="world-container">
            <Map />
          </div>
        </div>
      </section>

      <section className="home-offices">
        <div className="width-content">
          <h2 className="text--dark-purple title--xxl home-offices__title">
            {t('homePage.offices.title')}
          </h2>
          <div className="home-offices__further-information">
            <div>
              <p className="text--dark-purple subtitle home-offices__further-information__description">
                {t('homePage.offices.description.1')}
                <strong>{t('homePage.offices.description.2s')}</strong>
                {t('homePage.offices.description.3')}
              </p>
              <a href="https://wa.me/+34692599391" target="_blank" className="button--first--large">
                <p className="paragraph--highlight">
                  {t('homePage.offices.button')}
                </p>
              </a>
            </div>
            <div>
              <figure className="home-offices__image">
                <img src={OfficeImage} alt="office" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple home-sign-up-now">
        <div className="width-content home-sign-up-now__content">
          <h2 className="text--gray-blue title--xxl home-sign-up-now__title">
            {t('homePage.signUpNow.title')}
          </h2>
          <div className="home-sign-up-now__content__description">
            <div>
              <p className="text--gray-blue subtitle">
                {t('homePage.signUpNow.description')}
              </p>
              <img className="home-sign-up-now__one-world-image" src={OneGreenWorld} alt="one-green-world" />
              <h3 className="text--gray-blue subtitle home-sign-up-now__entrepreneurship-text">
                {t('homePage.signUpNow.entrepreneurship.first')}
              </h3>
              <Link to="/courses">
                <p className="text--gray-blue">
                  {t('homePage.signUpNow.entrepreneurship.second.1')}
                  <strong>{t('homePage.signUpNow.entrepreneurship.second.2s')}</strong>
                </p>
              </Link>
              <img src={TwoWorlds} alt="two-worlds" />
              <h3 className="text--gray-blue subtitle home-sign-up-now__entrepreneurship-technology-text">
                {t('homePage.signUpNow.entrepreneurshipTechnology.first')}
              </h3>
              <Link to="/courses">
                <p className="text--gray-blue">
                  {t('homePage.signUpNow.entrepreneurshipTechnology.second.1')}
                  <strong>{t('homePage.signUpNow.entrepreneurshipTechnology.second.2s')}</strong>
                </p>
              </Link>
            </div>
            <div className="home-sign-up-now__schedule-video">
              <div className="background-color--light-purple home-sign-up-now__schedule-block">
                <img src={MonthlySchedule} alt="week-calendar" />
                <div className="home-sign-up-now__our-community-block" style={{ backgroundImage: `url(${BlockOurCommunity})` }}>
                  <h2 className="text--gray-blue title--standard home-sign-up-now__our-community-block__text">
                    {t('homePage.signUpNow.block.1')}
                    <br />
                    {t('homePage.signUpNow.block.2')}
                    </h2>
                </div>
              </div>
              <Link to="/courses">
                <p className="text--gray-blue home-sign-up-now__our-community-information">
                  {t('homePage.signUpNow.community')}
                </p>
              </Link>
            </div>
          </div>
          <Link to="/enrollment" className="button--third--small home-sign-up-now__content__button">
            <p className="paragraph--highlight text--gray-blue">
              {t('homePage.signUpNow.button')}
            </p>
          </Link>
        </div>
      </section>

      <section className="home-sponsors">
        <div className="width-content home-sponsors__content">
          <div className="home-sponsors__content__title-container" style={{ backgroundImage: `url(${ComputerScreen})` }}>
            <h2 className="title--xxl text--dark-purple">
              {t('homePage.sponsors.title.1')}
              <br />
              {t('homePage.sponsors.title.2')}
            </h2>
          </div>
          <div className="home-sponsors__content__sponsors-list">
            <figure className="home-sponsors__content__sponsors-list__image-container">
              <img src={PlayStationLogo} alt="playstation" />
            </figure>
            <figure className="home-sponsors__content__sponsors-list__image-container">
              <img src={SecondSponsorLogo} alt="second sponsor" />
            </figure>
            <figure className="home-sponsors__content__sponsors-list__image-container">
              <img src={UCadizLogo} alt="universidad cadiz" />
            </figure>
            <figure className="home-sponsors__content__sponsors-list__image-container">
              <img src={IkeaLogo} alt="ikea" />
            </figure>
            <figure className="home-sponsors__content__sponsors-list__image-container">
              <img src={HuaweiLogo} alt="huawei" />
            </figure>
            <figure className="home-sponsors__content__sponsors-list__image-container">
              <img src={AinderLogo} alt="ainder" />
            </figure>
          </div>
        </div>
      </section>

      <section className="home-press">
        <div className="width-content home-press__content">
          <h2 className="title--xxl text--light-blue home-press__title">
            {t('homePage.press.title')}
          </h2>
          <div className="home-press__press-reviews-list">
            <article className="home-press__press-reviews-list__article">
              <img className="home-press__press-reviews-list__first-image" src={ElMundoLogo} alt="el-mundo-press" />
              <p className="home-press__press-reviews-list__article__text">
                {t('homePage.press.elMundo.1')}
                <strong>{t('homePage.press.elMundo.2s')}</strong>
                {t('homePage.press.elMundo.3')}
                <strong>{t('homePage.press.elMundo.4s')}</strong>”
              </p>
            </article>
            <article className="home-press__press-reviews-list__article">
              <img className="home-press__press-reviews-list__second-image" src={EmprendedoresLogo} alt="emprendedores-press" />
              <p className="home-press__press-reviews-list__article__text">
                {t('homePage.press.emprendedores.1')}
                <strong>{t('homePage.press.emprendedores.2s')}</strong>
                {t('homePage.press.emprendedores.3')}
                <strong>{t('homePage.press.emprendedores.4s')}</strong>
                {t('homePage.press.emprendedores.5')}
              </p>
            </article>
            <article className="home-press__press-reviews-list__article">
              <img className="home-press__press-reviews-list__third-image" src={DiarioDeJerezLogo} alt="diario-de-jerez-press" />
              <p className="home-press__press-reviews-list__article__text">
              {t('homePage.press.diarioJerez.1')}
              <strong>{t('homePage.press.diarioJerez.2s')}</strong>
              {t('homePage.press.diarioJerez.3')}
              <strong>{t('homePage.press.diarioJerez.4s')}</strong>
              {t('homePage.press.diarioJerez.5')}
              <strong>{t('homePage.press.diarioJerez.6s')}</strong>
              {t('homePage.press.diarioJerez.7')}
              </p>
            </article>
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple home-faqs">
        <div className="width-content home-faqs__content" style={{ backgroundImage: `url(${FaqsImage})` }}>
          <h2 className="title--xxl text--gray-blue">
            {t('homePage.faqs.title')}
          </h2>
          <p className="subtitle text--gray-blue home-faqs__description">
            {t('homePage.faqs.description')}
          </p>
          <ul className="home-faqs__questions-list">
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="home-faqs-first-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="home-faqs-first-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('homePage.faqs.questions.q1')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('homePage.faqs.questions.a1.1')}
                  <br />
                  {t('homePage.faqs.questions.a1.2')}
                  <br />
                  {t('homePage.faqs.questions.a1.3')}
                  <br />
                  {t('homePage.faqs.questions.a1.4')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="home-faqs-second-question" className="home-faqs__input-question"/>
              <label className="home-faqs__question-container" htmlFor="home-faqs-second-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('homePage.faqs.questions.q2')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('homePage.faqs.questions.a2.1')}
                  <br />
                  {t('homePage.faqs.questions.a2.2')}
                  <br />
                  {t('homePage.faqs.questions.a2.3')}
                  <br />
                  {t('homePage.faqs.questions.a2.4')} 
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="home-faqs-third-question" className="home-faqs__input-question"/>
              <label className="home-faqs__question-container" htmlFor="home-faqs-third-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('homePage.faqs.questions.q3')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('homePage.faqs.questions.a3.1')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a3.2')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a3.3')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a3.4')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a3.5')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="home-faqs-fourth-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="home-faqs-fourth-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('homePage.faqs.questions.q4')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('homePage.faqs.questions.a4.1')}
                  <br />
                  &nbsp;&nbsp;{t('homePage.faqs.questions.a4.2')}
                  <br />
                  &nbsp;&nbsp;{t('homePage.faqs.questions.a4.3')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a4.4')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a4.5')}
                  <br />
                  <br />
                  {t('homePage.faqs.questions.a4.6')}
                  <br />
                  {t('homePage.faqs.questions.a4.7')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="home-faqs-fifth-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="home-faqs-fifth-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('homePage.faqs.questions.q5')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('homePage.faqs.questions.a5')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="home-faqs-sixth-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="home-faqs-sixth-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('homePage.faqs.questions.q6')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('homePage.faqs.questions.a6.1')}
                  <br />
                  {t('homePage.faqs.questions.a6.2')}
                </p>
              </div>
            </li>
          </ul>
          <p className="subtitle text--gray-blue home-faqs__contact-text">
            {t('homePage.faqs.contactDescription')}
          </p>
          <button className="button--third--small">
            <p className="paragraph--highlight">
              {t('homePage.faqs.contactButton')}
            </p>
          </button>
        </div>
      </section>

      {/* <section className="worlds">
        <div className="worlds__content">
          <div className="worlds__content__main-text">
            <h2 className="worlds__main-text__title">Conoce nuestros mundos</h2>
            <p className="worlds__main-text-description">
              Más de <strong>1500 personas</strong>, ya se han formado con nosotros ¿y tú? Te esperamos en la <strong>siguiente
              dimensión de la educación</strong>, el <strong>futuro</strong> es hoy.
            </p>
          </div>
          <div className="worlds__list">
            <div className="worlds__list__world">
              <figure className="worlds__list__world__image-container">
                <img className="worlds__list__world__image" src={KidRobot} alt="person-photo" />
              </figure>
              <div className="worlds__list__world__title-container">
                <h3 className="worlds__list__world__title">EpicKids Zone</h3>
              </div>

              <Link to="/kids" className="worlds__list__world__description">
                <p className="worlds__list__world__description-text--main-text worlds__list__world__description-text-epickids">
                  Desde 7 hasta 12 años
                  <br /><br />
                </p>
                <p className="worlds__list__world__description-text worlds__list__world__description-text-epickids">
                  Inquieta, curiosa, creativa, soñad@ra... <strong>Construye tu idea</strong>, diseña con tecnología,
                  rodéate de niños y niñas como tú.
                  <br />Dale caña a tus  habilidades y pasa al <strong>siguiente nivel.</strong>
                  <br />Estamos en el <strong>metaverso</strong> ¿vienes?
                </p>
              </Link>

            </div>
            <Link to="/newGeneration" className="worlds__list__world">
              <figure className="worlds__list__world__image-container">
                <img className="worlds__list__world__image" src={WomanDisc} alt="person-photo" />
              </figure>
              <div className="worlds__list__world__title-container">
                <h3 className="worlds__list__world__title">NextGeneration Zone</h3>
              </div>

              <div className="worlds__list__world__description">
                <p className="worlds__list__world__description-text--main-text worlds__list__world__description-text-nextgeneration">
                  Desde los 14 años
                  <br /><br />
                </p>
                <p className="worlds__list__world__description-text worlds__list__world__description-text-nextgeneration">
                  <strong>Jóvenes, rebeldes, inconformistas, soñadores, tecnológicos y autodidactas...</strong>
                  <br /><br />
                  Sueñas con crear empleo, cambiar vidas, o cambiar la tuya con un gran empleo, necesitas más que
                  los títulos. Conviértete en líder, resolutivo, entiende el fracaso como aprendizaje, luchador,
                  creativo y empático.. Sabemos convertir sueños en realidades, <strong>#somosepic</strong>
                </p>
              </div>
            </Link>
            <Link to="/genuine" className="worlds__list__world">
              <figure className="worlds__list__world__image-container">
                <img className="worlds__list__world__image" src={GenuinePhoto} alt="person-photo" />
              </figure>
              <div className="worlds__list__world__title-container">
                <h3 className="worlds__list__world__title">EpicGenuine Zone</h3>
              </div>

              <div className="worlds__list__world__description">
                <p className="worlds__list__world__description-text--main-text worlds__list__world__description-text-genuine">
                  Desde los 8 años
                  <br /><br />
                </p>
                <p className="worlds__list__world__description-text worlds__list__world__description-text-genuine">
                  El puente para la <strong>integración</strong> profesional en los empleos de hoy y mañana. Desarrolla tú
                  potencial al <strong>siguiente nivel</strong>. Para todas las personas con diversidad funcional.
                </p>
              </div>
            </Link>
            <Link to="/oldSchool" className="worlds__list__world">
              <figure className="worlds__list__world__image-container">
                <img className="worlds__list__world__image" src={OldWoman} alt="person-photo" />
              </figure>
              <div className="worlds__list__world__title-container">
                <h3 className="worlds__list__world__title">OldSchool Zone</h3>
              </div>

              <div className="worlds__list__world__description">
                <p className="worlds__list__world__description-text--main-text worlds__list__world__description-text-oldschool">
                  Desde: si no te suena la palabra CRUSH este es tu sitio
                  <br /><br />
                </p>
                <p className="worlds__list__world__description-text worlds__list__world__description-text-oldschool">
                  Curioso, con afán de aprendizaje, <strong>en la flor de la vida</strong>, en busca de la felicidad o el arca perdida,
                  por conocer personas interesantes, desarrollar nuevas habilidades tech o encontrar un sitio que te haga
                  feliz. Razón: AQUÍ.
                </p>
              </div>
            </Link>
            <Link to="/teacherInnovation" className="worlds__list__world">
              <figure className="worlds__list__world__image-container">
                <img className="worlds__list__world__image" src={VirtualReality} alt="person-photo" />
              </figure>
              <div className="worlds__list__world__title-container">
                <h3 className="worlds__list__world__title">Teacher Innovation Zone</h3>
              </div>

              <div className="worlds__list__world__description">
                <p className="worlds__list__world__description-text--main-text worlds__list__world__description-text-teacherinnovation">
                  Profes de todo el mundo están con nosotros. La <strong>comunidad</strong> más molona del planeta.
                  <br /><br />
                </p>
                <p className="worlds__list__world__description-text worlds__list__world__description-text-teacherinnovation">
                  Da rienda suelta a tu creatividad en un <strong>espacio cooperativo</strong>, comparte, diseña, sigue
                  cambiando el mundo y sobre todo haz que tus alumnos sigan flipando contigo. No estás solo, aquí hay
                  más locos <strong>apasionados de la docencia</strong>, como tú. 
                </p>
              </div>
            </Link>
            <Link to="/ecommerce" className="worlds__list__world">
              <figure className="worlds__list__world__image-container">
                <img className="worlds__list__world__image" src={RobotEcommerce} alt="person-photo" />
              </figure>
              <div className="worlds__list__world__title-container">
                <h3 className="worlds__list__world__title">Epic Ecommerce</h3>
              </div>

              <div className="worlds__list__world__description">
                <p className="worlds__list__world__description-text--main-text worlds__list__world__description-text-ecommerce">
                  Epic lleva años siendo una forma de vida para el que lo descubre y queremos que lo lleves, que lo
                  sientas tuyo y que forme parte de ti. 
                </p>
              </div>
            </Link>
          </div>

          <div className="worlds-call-to-action">
            <h2 className="worlds-call-to-action__title">Descubre tu mundo</h2>
            <Link className="button-signup-signin worlds-call-to-action__signup" to="/plans">Registrarse</Link>
            <a className="button-signup-signin worlds-call-to-action__login" href="https://campus.epicgrouplab.com">Iniciar sesión</a>
          </div>
        </div>
      </section> */}
    </main>
  )
}

export default HomePage