import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const MatchReferenceCode = _ => {
  const params = useParams()
  const navigate = useNavigate()
  const checkReferenceCode = referenceCode => {
    if (params.id === referenceCode){
      return params.id
    } else {
      return "error-matched"
    }
  }
  
  const [id, setId] = useState(params.id ? checkReferenceCode("moniquita") : "")
  
  useEffect(_ => {
    if (id === "error-matched") {
      navigate("/error-page")
    }
  }, [id])

  return id
}

export default MatchReferenceCode