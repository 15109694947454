import React from 'react'

import MapPointComponent from './MapPointComponent'
import SchoolsLists from '../SchoolsLists/SchoolsLists'

import WorldCurved from '../../assets/images/world-curved.png'

import './mapPointComponent.scss'
import './map.scss'

const Map = _ => {
  return (
    <div className="map-main__map-container">
      <div className="map-main__map-content" style={{ backgroundImage: `url(${WorldCurved})` }}>
        <div className="map-main__map-points-container">
          <MapPointComponent
            componentClass='central-map-point'
            title="EpicGroup Lab Central"
            link="www.epicgrouplab.com" />
          <MapPointComponent
            componentClass='sotogrande-spain-map-point'
            title="EpicGroup Lab Sotogrande"
            link="www.ims-sotogrande.epicgrouplab.com"/>
          <MapPointComponent
            componentClass='valencia-spain-map-point'
            title="EpicGroup Lab Valencia"
            link="www.valencia.epicgrouplab.com"/>
          <MapPointComponent
            componentClass='oviedo-spain-map-point'
            title="EpicGroup Lab Oviedo"
            link="www.oviedo.epicgrouplab.com"/>
          <MapPointComponent
            componentClass="madrid-spain-map-point"
            title="EpicGroup Lab Madrid"
            link="www.madrid.epicgrouplab.com"/>
          <MapPointComponent
            componentClass='santafe-mexico-map-point'
            title="EpicGroup Lab Santa Fe"
            link="www.santafe.mx.epicgrouplab.com" />
          <MapPointComponent
            componentClass="merida-mexico-map-point"
            title="EpicGroup Lab Mérida"
            link="www.merida.epicgrouplab.com"/>
          <MapPointComponent
            componentClass='houston-united-states-map-point'
            title="EpicGroup Lab Houston"
            link="www.houston.epicgrouplab.com" />
          <MapPointComponent
            componentClass='lima-peru-map-point'
            title="EpicGroup Lab Lima"
            link="www.peru.epicgrouplab.com" />
          <MapPointComponent
            componentClass='arequipa-peru-map-point'
            title="EpicGroup Lab Arequipa"
            link="www.peru.epicgrouplab.com" />
          <MapPointComponent
            componentClass='trujillo-peru-map-point'
            title="EpicGroup Lab Trujillo"
            link="www.peru.epicgrouplab.com" />
        </div>
      </div>

      <div className="map-main__map-points-list">
        <SchoolsLists />
      </div>
    </div>
  )
}

export default Map