import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import EpicHeader from '../../assets/images/Epic_imagotipo-black--03.png'
import EpicImagotipo from '../../assets/images/Epic-imagotipo-horizontal-positivo(1)--03.png'
import EpicHeaderIsotipo from '../../assets/images/Epic-isotipogroup--03.png'
import YoutubeWhite from '../../assets/images/youtube--white.svg'
import TiktokWhite from '../../assets/images/tiktok--white.svg'
import TwitterWhite from '../../assets/images/twitter--white.svg'
import FacebookWhite from '../../assets/images/facebook--white.svg'
import InstagramWhite from '../../assets/images/instagram--white.svg'
import LinkedinWhite from '../../assets/images/linkedin--white.svg'
import WhatsappWhite from '../../assets/images/whatsapp--white.svg'
import EmailWhite from '../../assets/images/mail--white.svg'
import SpainCircleFlag from '../../assets/images/spain-circle-flag.png'
import USACircleFlag from '../../assets/images/usa-circle-flag.png'

import '../../assets/styles/components/buttons.scss'
import './header.scss'

const Header = ({ headerBackgroundColor, goInsideOption, plansOption, communityOption = 'active' }) => {
  const [ matchWidthMobile, setMatchWidthSize ] = useState(false)
  const { t, i18n } = useTranslation();
  
  const VerifyResize = _ => {
    console.log("resizeeeee")
    const matchWidth = window.matchMedia('(max-width: 1024px)')
    
    if (matchWidth.matches) {
      setMatchWidthSize(true)
    } else {
      setMatchWidthSize(false)
    }
  }
  


  const closeDropdown = _ => {
    if (document.getElementById('home-header__burger-button__input').checked) {
      document.getElementById('home-header__burger-button__input').checked = false
    }
  }
  
  const changeLanguage = (event, lng) => {
    const imgTarget = event.currentTarget.querySelector("img")

    if (i18n.language === 'es') {
      i18n.changeLanguage('en')
      imgTarget.src = SpainCircleFlag
    } else if (i18n.language === 'en') {
      i18n.changeLanguage('es')
      imgTarget.src = USACircleFlag
    }
  }

  useEffect(_ => {
    const matchWidth = window.matchMedia('(max-width: 1024px)');

    if (matchWidth.matches) {
      setMatchWidthSize(true); // Establece el valor en "true" si el ancho es menor a 1024px
    }
    
    const VerifyResize = () => {
      if (matchWidth.matches) {
        setMatchWidthSize(true); // Establece el valor en "true" si el ancho es menor a 1024px
      } else {
        setMatchWidthSize(false); // Establece el valor en "false" si el ancho es mayor a 1024px
      }
    };
    
    window.addEventListener('resize', VerifyResize);
    return () => {
      window.removeEventListener('resize', VerifyResize);
    };
  }, [])

  const initialImage = matchWidthMobile ? EpicHeaderIsotipo : EpicHeader;

  return (
    <header className={`home-header ${headerBackgroundColor}`}>
      <div className={`header_content ${matchWidthMobile ? "" : "width-content"}`}>
        <Link className="header__content__image-container" to="/" onClick={closeDropdown}>
          <img
            className="header__content__image"
            src={initialImage}
            alt="header" />
        </Link>

        <input type="checkbox" name="" id="home-header__burger-button__input" />
        <div className="home-header__burger-button-container">
          <label htmlFor="home-header__burger-button__input">
            <div className="home-header__burger-button">
              <div className="home-header__burger-button__line"></div>
              <div className="home-header__burger-button__line"></div>
              <div className="home-header__burger-button__line"></div>
            </div>
          </label>

          <nav className="home-header__navbar">
            <Link
              className="text--dark-purple home-header__text"
              to="/home-page"
              onClick={closeDropdown}>{t('header.home')}</Link>
            <Link
              className="text--dark-purple home-header__text"
              to="/centers"
              onClick={closeDropdown}>{t('header.centers')}</Link>
            <Link
              className="text--dark-purple home-header__text"
              to="/enrollment"
              onClick={closeDropdown}>{t('header.enroll')}</Link>
            <Link
              className="text--dark-purple home-header__text"
              to="/courses"
              onClick={closeDropdown}>{t('header.courses')}</Link>
            <Link
              className="text--dark-purple home-header__text"
              to="/demo-class"
              onClick={closeDropdown}>{t('header.contact')}</Link>
          </nav>

          <ul className="home-header__social-media-list">
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.youtube.com/channel/UCamC0bKNKzL9SYxjgSqWHIA"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={YoutubeWhite} alt="youtube" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.tiktok.com/@epicgrouplab?is_from_webapp=1&sender_device=pc"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={TiktokWhite} alt="tiktok" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://twitter.com/EpickidsL?s=20&t=HLt-MWBeGZ3lpMN6DPyVpw"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={TwitterWhite} alt="twitter" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.facebook.com/EpicGrouplab"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={FacebookWhite} alt="facebook" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.instagram.com/_epicgroup/"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={InstagramWhite} alt="instagram" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.linkedin.com/company/epickids-lab/"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={LinkedinWhite} alt="linkedin" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a className="home-header__social-media-list__container" href="https://wa.me/+34692599391" target="_blank">
                <img className="home-header__social-media-list__icon" src={WhatsappWhite} alt="whatsapp" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a className="home-header__social-media-list__container" href="" target="_blank">
                <img className="home-header__social-media-list__icon" src={EmailWhite} alt="email" />
              </a>
            </li>
          </ul>
        </div>

        <div className="header_content_buttons_container">
          <figure
          className="home-header__language-button"
          onClick={event => changeLanguage(event, "en")}>
            <img src={USACircleFlag} alt="spain-flat" />
          </figure>
          
          <a
            className="button--second"
            href="http://campus.epicgrouplab.com"
            onClick={closeDropdown}>
              <p className="paragraph--highlight">{t('header.login')}</p>
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header