import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// Switch is now Routes

import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Layout from './components/Layout/Layout'
// import Home from './pages/Home/Home'
import HomePage from './pages/HomePage/HomePage'
import Enrollment from './pages/Enrollment/Enrollment'
import Courses from './pages/Courses/Courses'
import Mentors from './pages/Mentors/Mentors'
import Centers from './pages/Centers/Centers'

import Entrepreneurship from './pages/Entrepreneurship/Entrepreneurship'
import EntrepreneurshipTechnology from './pages/EntrepreneurshipTechnology/EntrepreneurshipTechnology'
import SignupEntrepreneurship from './pages/SignUpEntrepreneurship/SignupEntrepreneurship';
import SignupEntrepreneurshipTechnology from './pages/SignupEntrepreneurshipTechnology/SignupEntrepreneurshipTechnology';
import SignupEntrepreneurshipTechnologyTrimestral from './pages/SignupEntrepreneurshipTechnologyTrimestral/SignupEntrepreneurshipTechnologyTrimestral'
import SignupEntrepreneurshipTechnologyEnglishOnlineMonthly from './pages/SignupEntrepreneurshipTechnologyEnglishOnlineMonthly/SignupEntrepreneurshipTechnologyEnglishOnlineMonthly';
import SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly from './pages/SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly/SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly';
import SignupEnglishOnlineMonthly from './pages/SignupEnglishOnlineMonthly/SignupEnglishOnlineMonthly';
import SignupEnglishOnlineQuarterly from './pages/SignupEnglishOnlineQuarterly/SignupEnglishOnlineQuarterly';
import SignupVideogamesOnlineMonthly from './pages/SignupVideogamesOnlineMonthly/SignupVideogamesOnlineMonthly';
import SignupVideogamesOnlineQuarterly from './pages/SignupVideogamesOnlineQuarterly/SignupVideogamesOnlineQuarterly';
import SignupNFTClass from './pages/SignupNFTClass/SignupNFTClass.';
import SignupBootcampInternational from './pages/SignupBootcampInternational/SignupBootcampInternational';
import LegalWarning from './pages/LegalWarning/LegalWarning';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import CookiesPolicy from './pages/CookiesPolicy/CookiesPolicy';
import TermsOfSale from './pages/TermsOfSale/TermsOfSale';
import WarningAboutUseVrTechnology from './pages/WarningAboutUseVrTechnology/WarningAboutUseVrTechnology'
import ConsentToUseImages from './pages/ConsentToUseImages/ConsentToUseImages'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Building from './pages/Building/Building'
import ImageLoader from './components/ImageLoader/ImageLoader';
import AppleVerificationFile from './assets/downloadable-files/apple-developer-merchantid-domain-association.unknown'
// import logo from './logo.svg';
// import './App.css';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/.well-known/apple-developer-merchantid-domain-association" element={AppleVerificationFile} />
          {/* <Route path="*" element={<Building />}></Route> */}
          <Route exact path="/" element={<ImageLoader />}></Route>
          <Route element={<Layout />} >
            <Route exact path='/home-page' element={<HomePage/>} />
            <Route exact path='/enrollment' element={<Enrollment />} />
            <Route exact path='/enrollment/:id' element={<Enrollment />} />
            <Route exact path='/courses' element={<Courses />} />
            <Route exact path='/mentors' element={<Mentors />} />
            <Route exact path='/centers' element={<Centers />} />

            {/* <Route exact path="/open-a-center" element={<OpenACenter />} /> */}
            <Route exact path="/entrepreneurship" element={<Entrepreneurship />} />
            <Route exact path="/entrepreneurship-technology" element={<EntrepreneurshipTechnology />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology" element={<SignupEntrepreneurshipTechnology />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology/:id" element={<SignupEntrepreneurshipTechnology />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology/:id" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-trimestral" element={<SignupEntrepreneurshipTechnologyTrimestral />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-trimestral" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-trimestral/:id" element={<SignupEntrepreneurshipTechnologyTrimestral />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-trimestral/:id" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-english-online-monthly" element={<SignupEntrepreneurshipTechnologyEnglishOnlineMonthly />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-english-online-monthly" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-english-online-quarterly" element={<SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-english-online-quarterly" element={<Building />} />
            {/* <Route exact path="/sign-up-english-online-monthly" element={<SignupEnglishOnlineMonthly />} /> */}
            <Route exact path="/sign-up-english-online-monthly" element={<Building />} />
            {/* <Route exact path="/sign-up-english-online-quarterly" element={<SignupEnglishOnlineQuarterly />} /> */}
            <Route exact path="/sign-up-english-online-quarterly" element={<Building />} />
            {/* <Route exact path="/sign-up-videogames-online-monthly" element={<SignupVideogamesOnlineMonthly />} /> */}
            <Route exact path="/sign-up-videogames-online-monthly" element={<Building />} />
            {/* <Route exact path="/sign-up-videogames-online-quarterly" element={<SignupVideogamesOnlineQuarterly />} /> */}
            <Route exact path="/sign-up-videogames-online-quarterly" element={<Building />} />
            {/* <Route exact path="/sign-up-bootcamp-international" element={<SignupBootcampInternational />} /> */}
            <Route exact path="/sign-up-bootcamp-international" element={<Building />} />
            <Route exact path="/legal-warning" element={<LegalWarning />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/cookies-policy" element={<CookiesPolicy />} />
            <Route exact path="/terms-of-sale" element={<TermsOfSale />} />
            <Route exact path="/warning-about-the-use-of-vr-technology" element={<WarningAboutUseVrTechnology />} />
            <Route exact path="/consent-to-use-images" element={<ConsentToUseImages />} />
            {/* <Route exact path="/demo-class" element={<SignupNFTClass />} /> */}
            <Route exact path="/demo-class" element={<Building />} />
            <Route exact path="/building" element={<Building />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>


    // </Route>
    
    // <AuthProvider>
    //   <BrowserRouter>
    //     <Switch>
    //       <Route exact path="/" component={Home}></Route>
    //     </Switch>
    //   </BrowserRouter>
    // </AuthProvider>
  );
}

export default App;
