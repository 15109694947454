import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Map from '../../components/Map/Map'

import CentersHeroImage from '../../assets/images/centers-hero-image.png'
import PlanetsTogether from '../../assets/images/planets-together.png'
import StepsBackground from '../../assets/images/steps-background.png'
import RobotHead from '../../assets/images/robot-head.png'
import Heart from '../../assets/images/heart.png'
import Prize from '../../assets/images/prize.png'
import Rocket from '../../assets/images/rocket.png'
import BagOfMoney from '../../assets/images/bag-of-money.png'
import ChevronRight from '../../assets/images/chevron-right.svg'
import FaqsImage from '../../assets/images/faqs-image.png'

import './centers.scss'
import '../../assets/styles/components/world.scss'

const Centers = _ => {
  const [mobileSize, setMobileSize] = useState(true)
  const [ t, i18n ] = useTranslation()

  const MaxWidthScreen = _ => {
    const screenSize = window.matchMedia('(max-width: 1024px)')
    if(screenSize.matches) {
      setMobileSize(true)
    } else {
      setMobileSize(false)
    }
  }
  document.addEventListener('resize', MaxWidthScreen)

  useEffect(_ => {
    MaxWidthScreen()
  }, [])

  return (
    <main className="home-main centers">
      <section className="background-color--light-purple centers__hero">
        <div className="width-content centers__hero__content">
          <div className="centers__hero__description-container">
            <h1 className="title--xxl text--gray-blue">
              {t('centers.hero.title')}
            </h1>
            <p className="subtitle text--gray-blue centers__hero__description-container__text">
              {t('centers.hero.description.1')}
              <strong>{t('centers.hero.description.2s')}</strong>
              {t('centers.hero.description.3')}
              <strong>{t('centers.hero.description.4s')}</strong>
              {t('centers.hero.description.5')}
              <br />
              <br />
              {t('centers.hero.description.6')}
              <strong>{t('centers.hero.description.7s')}</strong>
              {t('centers.hero.description.8')}
              <strong>{t('centers.hero.description.9s')}</strong>
              {t('centers.hero.description.10')}
            </p>
            <button className="button--third--small">
              <p className="paragraph--highlight">
                {t('centers.hero.button')}
              </p>
            </button>
          </div>
          <div className="centers__hero__image-container">
            <img className="centers__hero__image" src={CentersHeroImage} alt="centeres-hero" />
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple centers__metaverses">
        <div className={`${mobileSize ? '' : 'width-content'} centers__metaverses__content`}>
          <div className="centers__metaverses__description-container">
            <div>
              <h2 className="text--gray-blue title--xxl">
                {t('centers.metaverses.title')}
              </h2>
              <p className="text--gray-blue subtitle centers__metaverses__description-container__text">
                {t('centers.metaverses.description.1')}
                <br />
                {t('centers.metaverses.description.2')}
              </p>
            </div>
            <div className="centers__metaverses__image-container">
              <img className="centers__metaverses__description-container__image" src={PlanetsTogether} alt="planets-together" />
            </div>
          </div>
          <div className="world-container">
            <Map />
          </div>
          <div className="centers__metaverses__data-container">
            <h3 className="text--gray-blue title--xxl centers__metaverses__data-container__data">
              {t('centers.metaverses.students.number')}
              <br />
              {t('centers.metaverses.students.text')}
            </h3>
            <h3 className="text--gray-blue title--xxl centers__metaverses__data-container__data">
              {t('centers.metaverses.schools.number')}
              <br />
              {t('centers.metaverses.schools.text')}
            </h3>
            <h3 className="text--gray-blue title--xxl centers__metaverses__data-container__data">
              {t('centers.metaverses.models.number')}
              <br />
              {t('centers.metaverses.models.text')}
            </h3>
          </div>
        </div>
      </section>

      <section className="centers__steps">
        <div className="width-content centers__steps__content">
          <div className="centers__steps__steps-container" style={{ backgroundImage: `url(${StepsBackground})` }}>
            <div className="centers__steps__description-container">
              <h2 className="title--xxl text--dark-purple centers__steps__description-container__title">
                {t('centers.steps.title')}
              </h2>
              <p className="subtitle text--dark-purple centers__steps__text">
                {t('centers.steps.description.1')}
                <strong>{t('centers.steps.description.2s')}</strong>
                {t('centers.steps.description.3')}
                <strong>{t('centers.steps.description.4s')}</strong>
                {t('centers.steps.description.5')}
              </p>
              <br />
              <p className="subtitle text--green centers__steps__text">
                {t('centers.steps.description.6')}
                <strong>{t('centers.steps.description.7s')}</strong>
                {t('centers.steps.description.8')}
                <strong>{t('centers.steps.description.9s')}</strong>
                {t('centers.steps.description.10')}
              </p>
            </div>
            <div className="background-color--light-purple centers__steps__steps-container__circle">
              <h3 className="title--xxl centers__steps__steps-container__circle__text">
                {t('centers.steps.first.number')}
              </h3>
              <h4 className="subtitle text--green centers__steps__steps-container__circle__step-text">
                {t('centers.steps.first.text')}
              </h4>
            </div>
            <div className="background-color--light-purple centers__steps__steps-container__circle">
              <h3 className="title--xxl centers__steps__steps-container__circle__text">
                {t('centers.steps.second.number')}
              </h3>
              <h4 className="subtitle text--green centers__steps__steps-container__circle__step-text">
                {t('centers.steps.second.text')}
              </h4>
            </div>
            <div className="background-color--light-purple centers__steps__steps-container__circle">
              <h3 className="title--xxl centers__steps__steps-container__circle__text">
                {t('centers.steps.third.number')}
              </h3>
              <h4 className="subtitle text--green centers__steps__steps-container__circle__step-text">
                {t('centers.steps.third.text')}
              </h4>
            </div>
            <div className="background-color--light-purple centers__steps__steps-container__circle">
              <h3 className="title--xxl centers__steps__steps-container__circle__text">
                {t('centers.steps.fourth.number')}
              </h3>
              <h4 className="subtitle text--green centers__steps__steps-container__circle__step-text">
                {t('centers.steps.fourth.text')}
              </h4>
            </div>
            <div className="background-color--light-purple centers__steps__steps-container__circle">
              <h3 className="title--xxl centers__steps__steps-container__circle__text">
                {t('centers.steps.fifth.number')}
              </h3>
              <h4 className="subtitle text--green centers__steps__steps-container__circle__step-text">
                {t('centers.steps.fifth.text')}
              </h4>
            </div>
            <div className="background-color--light-purple centers__steps__steps-container__circle">
              <h3 className="title--xxl centers__steps__steps-container__circle__text">
                {t('centers.steps.sixth.number')}
              </h3>
              <h4 className="subtitle text--green centers__steps__steps-container__circle__step-text">
                {t('centers.steps.sixth.text')}
              </h4>
            </div>
          </div>
          <div className="centers__steps__what-we-are-looking-for">
            <div>
              <h2 className="text--dark-purple title--xxl">
                {t('centers.whatWeAreLookingFor.title')}
              </h2>
              <div className="centers__steps__text-container">
                <p className="text--dark-purple subtitle centers__steps__text">
                  {t('centers.whatWeAreLookingFor.description.1')}
                  <strong>{t('centers.whatWeAreLookingFor.description.2s')}</strong>
                  {t('centers.whatWeAreLookingFor.description.3')}
                  <strong>{t('centers.whatWeAreLookingFor.description.4s')}</strong>
                  {t('centers.whatWeAreLookingFor.description.5')}
                </p>
                <br />
                <p className="text--green subtitle centers__steps__text">
                  {t('centers.whatWeAreLookingFor.description.6')}
                  <strong>{t('centers.whatWeAreLookingFor.description.7s')}</strong>
                  {t('centers.whatWeAreLookingFor.description.8')}
                </p>
              </div>
            </div>
            <figure className="centers__steps__what-we-are-looking-for__image">
              <img src={RobotHead} alt="robot-head" />
            </figure>
          </div>
          <div className="centers__steps__qualities-list">
            <div className="centers__steps__qualities-list__quality">
              <img src={Heart} alt="heart" className="centers__steps__qualities-list__quality__image" />
              <p className="paragraph--highlight text--dark-purple">
                {t('centers.whatWeAreLookingFor.passion.1')}
                <strong>
                  {t('centers.whatWeAreLookingFor.passion.2s')}
                  <br />
                  {t('centers.whatWeAreLookingFor.passion.3s')}
                </strong>
                {t('centers.whatWeAreLookingFor.passion.4')}
                <br />
                {t('centers.whatWeAreLookingFor.passion.5')}
                <br />
                {t('centers.whatWeAreLookingFor.passion.6')}
              </p>
            </div>
            <div className="centers__steps__qualities-list__quality">
              <img src={Prize} alt="prize" className="centers__steps__qualities-list__quality__image" />
              <p className="paragraph--highlight text--dark-purple">
                <strong>{t('centers.whatWeAreLookingFor.commitment.1s')}</strong>
                {t('centers.whatWeAreLookingFor.commitment.2')}
                <br />
                {t('centers.whatWeAreLookingFor.commitment.3')}
                <br />
                {t('centers.whatWeAreLookingFor.commitment.4')}
                <br />
                {t('centers.whatWeAreLookingFor.commitment.5')}
              </p>
            </div>
            <div className="centers__steps__qualities-list__quality">
              <img src={Rocket} alt="rocket" className="centers__steps__qualities-list__quality__image" />
              <p className="paragraph--highlight text--dark-purple">
                <strong>{t('centers.whatWeAreLookingFor.vocation.1s')}</strong>
                {t('centers.whatWeAreLookingFor.vocation.2')}
                <br />
                <strong>{t('centers.whatWeAreLookingFor.vocation.3s')}</strong>
                {t('centers.whatWeAreLookingFor.vocation.4')}
                <br />
                {t('centers.whatWeAreLookingFor.vocation.5')}
                <br />
                {t('centers.whatWeAreLookingFor.vocation.6')}
              </p>
            </div>
            <div className="centers__steps__qualities-list__quality">
              <img src={BagOfMoney} alt="bag-of-money" className="centers__steps__qualities-list__quality__image" />
              <p className="paragraph--highlight text--dark-purple">
                <strong>{t('centers.whatWeAreLookingFor.investment.1s')}</strong>
                <br />
                {t('centers.whatWeAreLookingFor.investment.2')}
                <br />
                {t('centers.whatWeAreLookingFor.investment.3')}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="background-color--dark-purple home-faqs">
        <div className="width-content home-faqs__content" style={{ backgroundImage: `url(${FaqsImage})` }}>
          <h2 className="title--xxl text--gray-blue">
            {t('centers.faqs.title')}
          </h2>
          <p className="subtitle text--gray-blue home-faqs__description">
            {t('centers.faqs.description')}
          </p>
          <ul className="home-faqs__questions-list">
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="centers-faqs-first-question" className="home-faqs__input-question"/>
              <label className="home-faqs__question-container" htmlFor="centers-faqs-first-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('centers.faqs.questions.q1')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('centers.faqs.questions.a1.1')}
                  <br />
                  {t('centers.faqs.questions.a1.2')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="centers-faqs-second-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="centers-faqs-second-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('centers.faqs.questions.q2')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('centers.faqs.questions.a2.1')}
                  <br />
                  {t('centers.faqs.questions.a2.2')}
                  <br />
                  {t('centers.faqs.questions.a2.3')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="centers-faqs-third-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="centers-faqs-third-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('centers.faqs.questions.q3')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('centers.faqs.questions.a3')}
                </p>
              </div>
            </li>
            <li className="home-faqs__questions-list__question-answer-container">
              <input type="radio" name="home-faqs" id="centers-faqs-fourth-question" className="home-faqs__input-question" />
              <label className="home-faqs__question-container" htmlFor="centers-faqs-fourth-question">
                <h3 className="paragraph--highlight home-faqs__question-container__text">
                  {t('centers.faqs.questions.q4')}
                </h3>
                <img src={ChevronRight} alt="chevron-purple-right" />
              </label>
              <div className="background-color--light-purple home-faqs__answer-container">
                <p className="home-faqs__answer-container__text">
                  {t('centers.faqs.questions.a4.1')}
                  <br />
                  {t('centers.faqs.questions.a4.2')}
                </p>
              </div>
            </li>
          </ul>
          <p className="subtitle text--gray-blue home-faqs__contact-text">
            {t('centers.faqs.contactDescription')}
          </p>
          <button className="button--third--small">
            <p className="paragraph--highlight">
              {t('centers.faqs.contactButton')}
            </p>
          </button>
        </div>
      </section>
    </main>
  )
}

export default Centers