import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import GreenPoint from '../../assets/images/green-point.svg'
import ChevronRightGreen from '../../assets/images/chevron-right-green.svg'
import EnrollmentMainImage from '../../assets/images/enrollment-main-image.png'
import EnrollmentMainText from '../../assets/images/enrollment-main-text-container.png'
import EnrollmentNowImage from '../../assets/images/enrollment-now-section-image.png'

import GetReferenceCode from '../../components/MatchReferenceCode/MatchReferenceCode'
import SchoolsLists from '../../components/SchoolsLists/SchoolsLists'
// import BootcampInternationa from '../../components/BootcampInternational/BootcampInternational'
import '../../assets/styles/components/plans-cards.scss'
import './enrollment.scss'

const Enrollment = _ => {
  const id = GetReferenceCode()
  const [ t, i18n ] = useTranslation()
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // The substring is to skip the first letter, in this case the "#" symbol from the hash slot
      const scrollToElement = document.getElementById(location.hash.substring(1));
      if (scrollToElement) {
        scrollToElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <main className="home-main enrollment">
      <section className="background-color--dark-purple enrollment__hero">
        <div className="width-content enrollment__hero__content">
          <div className="enrollment__hero__content__description">
            <h1 className="title--xxl text--gray-blue">
              {t('enroll.hero.title')}
            </h1>
            <p className="subtitle text--gray-blue enrollment__hero__content__description__text">
              {t('enroll.hero.description.1')}
              <br />
              {t('enroll.hero.description.2')}
            </p>
            <div className="enrollment__hero__content__center-list">
              <SchoolsLists />
            </div>
          </div>
          <div className="enrollment__hero__content__container-visiting">
            <div className="enrollment__hero__content__visiting" style={{ backgroundImage: `url(${EnrollmentMainImage})` }}>
              <div className="enrollment__hero__content__visiting-text-block" style={{ backgroundImage: `url(${EnrollmentMainText})` }}>
                <p className="text--gray-blue enrollment__hero__content__visiting-text-block__text">
                  {t('enroll.hero.blockText')}
                </p>
              </div>
            </div>
            <button className="button--first--large">
              <p className="paragraph--highlight">
                {t('enroll.hero.button')}
              </p>
            </button>
          </div>
        </div>
      </section>

      <section className="enrollment__right-now">
        <div className="width-content enrollment__right-now__content">
          <div>
            <figure className="enrollment__right-now__content__image">
              <img src={EnrollmentNowImage} alt="virtual-reality-room" />
            </figure>
          </div>
          <div className="enrollment__right-now__description">
            <h2 className="title--xxl text--dark-purple">
              {t('enroll.rightNow.title')}
            </h2>
            <p className="subtitle text--dark-purple enrollment__right-now__description__text">
              {t('enroll.rightNow.description.1')}
              <strong>{t('enroll.rightNow.description.2s')}</strong>
              {t('enroll.rightNow.description.3')}
              <br />
              <br />
              {t('enroll.rightNow.description.4')}
              <strong>{t('enroll.rightNow.description.5s')}</strong>
              {t('enroll.rightNow.description.6')}
              <strong>{t('enroll.rightNow.description.7s')}</strong>
              {t('enroll.rightNow.description.8')}
            </p>
            <Link to="/demo-class" className="button--first--large">
              <p className="paragraph--highlight">{t('enroll.rightNow.button')}</p>
            </Link>
          </div>
        </div>
      </section>
      <section className="background-color--dark-purple enrollment__available-courses">
        <div className="width-content enrollment__available-courses__content">
          <div className="enrollment__available-courses__description-container">
            <h2 className="title--standard enrollment__available-courses__title">
              {t('enroll.availableCourses.title')}
            </h2>
            <p className="subtitle">
              {t('enroll.availableCourses.description.1')}
              <br />
              <br />
              {t('enroll.availableCourses.description.2')}
              <strong>{t('enroll.availableCourses.description.3s')}</strong>
            </p>
          </div>
          <div className="enrollment__available-courses__plans-container">
            <div className="plans-offer-two-sections-container entrepreneurship-technology-registration-plan">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.title.1')}
                    <br />
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.title.2')}
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.enroll.title')}
                  </strong>
                  <br />
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.enroll.description')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.enroll.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.enroll.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.enroll.oneTimePayment')}
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.course.title')}
                  </strong>
                  <br />
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.course.description')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.course.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.course.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.course.monthlyPayment')}
                  </small>
                </div>

                <Link className="plans-to-buy__list__epic-plan__more-information" to="/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.course.knowQuarterlyPlan')}
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </Link>

                <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-entrepreneurship-technology/${id}`}>
                  <p className="paragraph--highlight">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineMonthly.button')}
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan individual-more-hours-plan">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.title.1')}
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.title.2')}
                </h3>
              </div>

              <p className="text--light-blue paragraph--standard">
                <strong>
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.course.title')}
                </strong>
                <br />
                <br />
                {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.course.description')}
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.course.cost')}
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.course.price')}
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;{t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.course.quarterlyPayment')}
                </small>
              </div>

              <Link className="plans-to-buy__list__epic-plan__more-information" to="/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.course.knowQuarterlyPlan')}
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </Link>

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-entrepreneurship-technology-trimestral/${id}`}
              >
                <p className="paragraph--highlight">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyOnlineQuarterly.button')}
                </p>
              </Link>
            </article>

            <div className="plans-container__middle-line"></div>

            <div className="plans-offer-two-sections-container english-online-monthly">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    {t('enroll.availableCourses.englishOnlineMonthly.title.1')}
                    <br />
                    {t('enroll.availableCourses.englishOnlineMonthly.title.2')}
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>{t('enroll.availableCourses.englishOnlineMonthly.enroll.title')}</strong><br />
                  <br />
                  {t('enroll.availableCourses.englishOnlineMonthly.enroll.description')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.englishOnlineMonthly.enroll.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.englishOnlineMonthly.enroll.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.englishOnlineMonthly.enroll.oneTimePayment')}
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>{t('enroll.availableCourses.englishOnlineMonthly.course.title')}</strong><br />
                  <br />
                  {t('enroll.availableCourses.englishOnlineMonthly.course.description')}
                </p>
                <p className="text--light-blue text--small">
                  <br />
                  {t('enroll.availableCourses.englishOnlineMonthly.course.additional')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.englishOnlineMonthly.course.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.englishOnlineMonthly.course.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.englishOnlineMonthly.course.monthlyPayment')}
                  </small>
                </div>

                {/* <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a> */}

                <Link
                  className="button--third--small plans-to-buy__list__epic-plan__button"
                  to={`/sign-up-english-online-monthly/${id}`}
                >
                  <p className="paragraph--highlight">
                    {t('enroll.availableCourses.englishOnlineMonthly.button')}
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan english-online-quarterly">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  {t('enroll.availableCourses.englishOnlineQuarterly.title.1')}
                  <br />
                  {t('enroll.availableCourses.englishOnlineQuarterly.title.2')}
                </h3>
              </div>
              <p className="text--light-blue paragraph--standard">
                <strong>{t('enroll.availableCourses.englishOnlineQuarterly.course.title')}</strong><br />
                <br />
                {t('enroll.availableCourses.englishOnlineQuarterly.course.description')}
              </p>
              <p className="text--light-blue text--small">
                <br />
                {t('enroll.availableCourses.englishOnlineQuarterly.course.additional')}
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                {t('enroll.availableCourses.englishOnlineQuarterly.course.cost')}
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  {t('enroll.availableCourses.englishOnlineQuarterly.course.price')}
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;{t('enroll.availableCourses.englishOnlineQuarterly.course.quarterlyPayment')}
                </small>
              </div>

              {/* <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a> */}

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-english-online-quarterly/${id}`}
              >
                <p className="paragraph--highlight">
                  {t('enroll.availableCourses.englishOnlineQuarterly.button')}
                </p>
              </Link>
            </article>

            <div className="plans-container__middle-line-2"></div>

            <div className="plans-offer-two-sections-container videogames-online-monthly">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    {t('enroll.availableCourses.videogamesOnlineMonthly.title.1')}
                    <br />
                    {t('enroll.availableCourses.videogamesOnlineMonthly.title.2')}
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>{t('enroll.availableCourses.videogamesOnlineMonthly.enroll.title')}</strong><br />
                  <br />
                  {t('enroll.availableCourses.videogamesOnlineMonthly.enroll.description')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.videogamesOnlineMonthly.enroll.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.videogamesOnlineMonthly.enroll.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.videogamesOnlineMonthly.enroll.oneTimePayment')}
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>{t('enroll.availableCourses.videogamesOnlineMonthly.course.title')}</strong><br />
                  <br />
                  {t('enroll.availableCourses.videogamesOnlineMonthly.course.description')}
                </p>
                <p className="text--light-blue text--small">
                  <br />
                  {t('enroll.availableCourses.videogamesOnlineMonthly.course.additional')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.videogamesOnlineMonthly.course.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.videogamesOnlineMonthly.course.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.videogamesOnlineMonthly.course.monthlyPayment')}
                  </small>
                </div>

                {/* <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a> */}

                <Link
                  className="button--third--small plans-to-buy__list__epic-plan__button"
                  to={`/sign-up-videogames-online-monthly/${id}`}
                >
                  <p className="paragraph--highlight">
                    {t('enroll.availableCourses.videogamesOnlineMonthly.button')}
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan videogames-online-quarterly">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  {t('enroll.availableCourses.videogamesOnlineQuarterly.title.1')}
                  <br />
                  {t('enroll.availableCourses.videogamesOnlineQuarterly.title.2')}
                </h3>
              </div>
              <p className="text--light-blue paragraph--standard">
                <strong>{t('enroll.availableCourses.videogamesOnlineQuarterly.course.title')}</strong><br />
                <br />
                {t('enroll.availableCourses.videogamesOnlineQuarterly.course.description')}
              </p>
              <p className="text--light-blue text--small">
                <br />
                {t('enroll.availableCourses.videogamesOnlineQuarterly.course.additional')}
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                {t('enroll.availableCourses.videogamesOnlineQuarterly.course.cost')}
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  {t('enroll.availableCourses.videogamesOnlineQuarterly.course.price')}
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;{t('enroll.availableCourses.videogamesOnlineQuarterly.course.quarterlyPayment')}
                </small>
              </div>

              {/* <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a> */}

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-videogames-online-quarterly/${id}`}
              >
                <p className="paragraph--highlight">
                  {t('enroll.availableCourses.videogamesOnlineQuarterly.button')}
                </p>
              </Link>
            </article>

            <div className="plans-container__middle-line-3"></div>

            <div className="plans-offer-two-sections-container entrepreneurship-technology-english-monthly">
              <article className="plans-to-buy__list__epic-plan">
                <div className="plans-to-buy__list__epic-plan__title-container">
                  <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.title.1')}
                    <br />
                    {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.title.2')}
                  </h3>
                </div>

                <p className="text--light-blue paragraph--standard">
                  <strong>{t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.enroll.title')}</strong><br />
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.enroll.description')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.enroll.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.enroll.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.enroll.oneTimePayment')}
                  </small>
                </div>
              </article>

              <div className="plans-to-buy__middle-line"></div>

              <article className="plans-to-buy__list__epic-plan">
                <p className="text--light-blue paragraph--standard">
                  <strong>{t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.course.title')}</strong><br />
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.course.description')}
                </p>
                <p className="text--light-blue text--small">
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.course.additional')}
                </p>
                <br />
                <p className="text--green plans-to-buy__list__epic-plan__description">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.course.cost')}
                </p>
                <div className="plans-to-buy__list__call-to-action">
                  <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.course.price')}
                  </p>
                  <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                    &nbsp;&nbsp;{t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.course.monthlyPayment')}
                  </small>
                </div>

                {/* <a
                className="plans-to-buy__list__epic-plan__more-information"
                href="https://epicgrouplab.com/courses">
                  <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                    Conoce el plan trimestral
                  </p>
                  <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                    <img src={ChevronRightGreen} alt="chevron-right-green" />
                  </figure>
                </a> */}

                <Link
                  className="button--third--small plans-to-buy__list__epic-plan__button"
                  to={`/sign-up-entrepreneurship-technology-english-online-monthly/${id}`}
                >
                  <p className="paragraph--highlight">
                    {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineMonthly.button')}
                  </p>
                </Link>
              </article>
            </div>

            <article className="plans-to-buy__list__epic-plan entrepreneurship-technology-english-quarterly">
              <div className="plans-to-buy__list__epic-plan__title-container">
                <h3 className="subtitle text--dark-purple plans-to-buy__list__epic-plan__title">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.title.1')}
                  <br />
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.title.2')}
                </h3>
              </div>
              <p className="text--light-blue paragraph--standard">
                <strong>{t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.course.title')}</strong><br />
                <br />
                {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.course.description')}
              </p>
              <p className="text--light-blue text--small">
                <br />
                {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.course.additional')}
              </p>
              <br />
              <p className="text--green plans-to-buy__list__epic-plan__description">
                {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.course.cost')}
              </p>
              <div className="plans-to-buy__list__call-to-action">
                <p className="text--light-blue subtitle plans-to-buy__list__epic-plan__price">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.course.price')}
                </p>
                <small className="text--light-blue plans-to-buy__list__epic-plan__time">
                  &nbsp;&nbsp;{t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.course.quarterlyPayment')}
                </small>
              </div>

              {/* <a
              className="plans-to-buy__list__epic-plan__more-information"
              href="https://epicgrouplab.com/courses">
                <p className="text--light-blue plans-to-buy__list__epic-plan__more-information__text">
                  Conoce el plan trimestral
                </p>
                <figure className="plans-to-buy__list__epic-plan__more-information__arrow">
                  <img src={ChevronRightGreen} alt="chevron-right-green" />
                </figure>
              </a> */}

              <Link
                className="button--third--small plans-to-buy__list__epic-plan__button"
                to={`/sign-up-entrepreneurship-technology-english-online-quarterly/${id}`}
              >
                <p className="paragraph--highlight">
                  {t('enroll.availableCourses.entrepreneurshipTechnologyEnglishOnlineQuarterly.button')}
                </p>
              </Link>
            </article>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Enrollment