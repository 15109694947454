import React from 'react'

import './cookiesPolicy.scss'

const CookiesPolicy = _ => {
  return (
    <main className="home-main legal-warning-main">
      <section className="background-color--dark-purple legal-warning-main__hero">
        <div className="width-content legal-warning-main__hero__text-container">
          <h1 className="title--xxl">Política de Cookies</h1>
          <p className="legal-warning__paragraph">
            En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio
            Electrónico le informamos de que este Sitio Web, al igual que la mayoría de los sitios en Internet, usa Cookies
            para mejorar y optimizar la experiencia del usuario. A continuación encontrará información detallada sobre qué son
            las “Cookies”, qué tipología utiliza este sitio web, cómo cambiar la configuración de sus cookies, y qué ocurre
            si deshabilita las Cookies.
          </p>
        </div>
      </section>
      <section className="legal-warning-main__data">
        <div className="width-content">
          <h2 className="text--green legal-warning__text-second">¿Qué son las cookies?</h2>
          <p className="legal-warning__paragraph">
            Las cookies son archivos que se pueden descargar en su equipo a través de las páginas web. Son herramientas que
            tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros,
            permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de
            su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el
            servicio ofrecido.
          </p>

          <h2 className="text--green legal-warning__text-second">Tipos de cookies</h2>
          <p className="legal-warning__paragraph">
            Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se
            obtengan se pueden distinguir dos tipos: cookies propias y cookies de terceros.<br />
            <br />
            Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del
            cliente, pudiendo tratarse de cookies de sesión o cookies persistentes.<br />
            <br />
            Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los
            datos obtenidos: cookies técnicas, cookies de personalización, cookies de análisis, cookies publicitarias y
            cookies de publicidad comportamental.<br />
            <br />
            Para más información a este respecto puede consultar la Guía sobre el uso de las cookies de la <strong>Agencia
            Española de Protección de Datos</strong>.
          </p>

          <h2 className="text--green legal-warning__text-second">Cookies utilizadas en la Web</h2>
          <div className="cookies-policy__table">
            <strong className="cookies-policy__table__space-1 legal-warning__paragraph text--light-blue">
              NOMBRE
            </strong>
            <strong className="cookies-policy__table__space-2 legal-warning__paragraph text--light-blue">
              DESCRIPCIÓN/FINALIDAD
            </strong>
            <strong className="cookies-policy__table__space-3 legal-warning__paragraph text--light-blue">
              TIPO
            </strong>
            <strong className="cookies-policy__table__space-4 legal-warning__paragraph text--light-blue">
              CADUCIDAD
            </strong>
            <div className="cookies-policy__table__line"></div>
            <p className="cookies-policy__table__space-5 legal-warning__paragraph">ocn_accepted</p>
            <p className="cookies-policy__table__space-6  legal-warning__paragraph">
              Cookie usada para recordar la aceptación de la política de privacidad por parte del usuario.
            </p>
            <p className="cookies-policy__table__space-7 legal-warning__paragraph">Técnica</p>
            <p className="cookies-policy__table__space-8 legal-warning__paragraph">Persistente</p>
            <div className="cookies-policy__table__line"></div>
            <p className="cookies-policy__table__space-9 legal-warning__paragraph">_ga, _gali, _gat, _gid</p>
            <p className="cookies-policy__table__space-10 legal-warning__paragraph">
              Cookies de Google Analytics.<br />
              <br />
              Informes estadísticos sobre como los usuarios encuentran la página web y cómo la utilizan: páginas visitadas,
              tiempo de estancia, tipo de navegador…<br />
              <br />
              _ga i _gid: Se usan para distinguir a los usuarios.<br />
              <br />
              _gat: Se usa para limitar el porcentaje de solicitudes<br />
              <br />
              Más información
            </p>
            <p className="cookies-policy__table__space-11 legal-warning__paragraph">Analíticas, de terceros</p>
            <p className="cookies-policy__table__space-12 legal-warning__paragraph">
              _ga: 2 años<br />
              <br />
              _gid: 24 horas<br />
              <br />
              _gat: 10 minutos<br />
              <br />
              _gali: sesión
            </p>
            <div className="cookies-policy__table__line"></div>
            <p className="cookies-policy__table__space-13 legal-warning__paragraph">player, vuid</p>
            <p className="cookies-policy__table__space-14 legal-warning__paragraph">
              Cookies de Vimeo.<br />
              <br />
              Se instalan al mostrar cualquier vídeo de Vimeo en una página.
            </p>
            <p className="cookies-policy__table__space-15 legal-warning__paragraph">Personalización</p>
            <p className="cookies-policy__table__space-16 legal-warning__paragraph">Persistente</p>
            <div className="cookies-policy__table__line"></div>
          </div>

          <h2 className="text--green legal-warning__text-second">Cómo modificar la configuración de las Cookies</h2>
          <p className="legal-warning__paragraph">
            Nacho GA  asume que usted acepta el uso de cookies. No obstante, usted puede restringir, bloquear o borrar las
            cookies de este Sitio Web, o cualquier otra página, utilizando su navegador. En cada navegador la operativa es
            diferente, la función de «Ayuda» le mostrará cómo hacerlo.<br />
            <br />
            · Internet Explorer<br />
            · Safari<br />
            · Chrome<br />
            · Firefox<br />
            · Opera<br />
            · Edge<br />
            <br />
            Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las
            siguientes:<br />
            <br />
            · Ghostery<br />
            · Your online choices
          </p>

          <h2 className="text--green legal-warning__text-second">Qué ocurre si se deshabilitan las Cookies</h2>
          <p className="legal-warning__paragraph">
            Algunas funcionalidades de los servicios o áreas de este Sitio Web podrían no funcionar correctamente.
          </p>

          <h2 className="text--green legal-warning__text-second">Actualización de la Política de Cookies</h2>
          <p className="legal-warning__paragraph">
            Es posible que se actualice la política de cookies de la Página Web, por ello, le recomiendo revisar esta política
            cada vez que acceda a este Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué se usan
            las cookies.
          </p>
        </div>
      </section>
    </main>
  )
}

export default CookiesPolicy